import React from "react"
import { useLocalize } from "../../../packages/localization/client-side/useLocalize"
import { GetModulesModulesDto } from "./client"
import { correctGreen, darkGray } from "./colors"

export function ModuleCard({
    mod,
    onClick,
    selected,
}: {
    mod: GetModulesModulesDto
    onClick: () => void
    selected: boolean
}) {
    const localize = useLocalize()
    const disabled = !mod.canPlay
    const badges = mod.badges.filter((b) => b.earned !== undefined).length
    const maxBadges = mod.badges.length
    return (
        <div
            onClick={() => {
                if (!disabled) onClick()
            }}
            style={{
                backgroundColor: selected ? mod.color : "white",
                boxShadow: "0px 4px 20px 0px #BFBDB029",
                border: "1px solid #FAF3E7",
                borderRadius: 16,
                padding: 24,
                marginBottom: 16,
                minWidth: 480,
                color: selected ? "white" : darkGray,
                transition: "all 0.4s ease",
                cursor: disabled ? undefined : "pointer",
                position: "relative",
                overflow: "clip",
            }}>
            <svg
                style={{
                    position: "absolute",
                    right: 0,
                    top: 0,
                    width: 200,
                }}>
                <circle cx="200" cy="70" r="135" fill={selected ? "#fff4" : mod.color + "44"} />
            </svg>
            <div
                style={{
                    position: "absolute",
                    right: 28,
                    top: 38,
                    width: 72,
                    height: 72,
                    backgroundColor: selected ? "white" : mod.color,
                    WebkitMask: `url(${mod.icon}) no-repeat center`,
                    WebkitMaskSize: "contain",
                    mask: `url(${mod.icon}) no-repeat center`,
                    maskSize: "contain",
                    transition: "0.2s ease",
                }}
            />

            <div
                style={{
                    marginBottom: 16,
                    fontSize: 22,
                    fontWeight: 700,
                    color: !mod.canPlay ? "#888" : undefined,
                }}>
                {localize(mod.title ?? mod.name)}{" "}
                {!mod.canPlay && (
                    <div style={{ fontSize: 12, color: "#888", height: 0 }}>Not available yet</div>
                )}
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ height: 24 }}>
                    {maxBadges
                        ? Array(badges)
                              .fill(null)
                              .map((x, i) => (
                                  <Hexagon key={i} color={selected ? "#ffffff" : mod.color} />
                              ))
                              .concat(
                                  Array(maxBadges - badges)
                                      .fill(null)
                                      .map((x, i) => (
                                          <Hexagon
                                              key={i}
                                              color={(selected ? "#ffffff" : mod.color) + "88"}
                                          />
                                      ))
                              )
                        : undefined}
                </div>
                <div
                    style={{
                        marginLeft: 16,
                        fontWeight: "normal",
                        fontSize: 14,
                    }}>
                    {maxBadges ? badges + " / " + maxBadges : ""}
                </div>
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: 8,
                }}>
                <div
                    style={{
                        height: 6,
                        width: 100,
                        backgroundColor: "#F1F5F8",
                        marginTop: 7,
                        borderRadius: 3,
                    }}>
                    <div
                        style={{
                            height: 6,
                            width: Math.max(0, Math.min(1, mod.score / mod.maxScore)) * 100,
                            backgroundColor: correctGreen,
                            borderRadius: 3,
                        }}
                    />
                </div>
                <div
                    style={{
                        marginLeft: 16,
                        fontWeight: "normal",
                        fontSize: 14,
                    }}>
                    {mod.score ? mod.score + " / " + mod.maxScore : ""}
                </div>
            </div>
        </div>
    )
}

function Hexagon(props: { color: string }) {
    return (
        <svg
            width="20"
            height="16"
            viewBox="0 0 15 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 5.25309C0 4.45155 0.415873 3.70431 1.09714 3.28917L5.92381 0.335303C6.65397 -0.111767 7.56825 -0.111767 8.29841 0.335303L13.1238 3.28917C13.8063 3.70431 14.2222 4.45155 14.2222 5.25309V10.7744C14.2222 11.5472 13.8063 12.2945 13.1238 12.7096L8.29841 15.6647C7.56825 16.1118 6.65397 16.1118 5.92381 15.6647L1.09714 12.7096C0.415873 12.2945 0 11.5472 0 10.7744L0 5.25309Z"
                fill={props.color}
            />
        </svg>
    )
}
