import React from "react"
import { useOutletContext } from "react-router-dom"
import { server } from "../../../server"
import { GetLeaderboardLeadersDto, useLeaderboard } from "./client"
import { HomeContext } from "./Home"
import { useRedirect } from "./useRedirect"

export function Leaderboard() {
    const { disableLeaderboards } = useOutletContext<HomeContext>()
    const [selectedModule, setSelectedModule] = React.useState("all")
    const [selectedDepartment, setSelectedDepartment] = React.useState("all")
    const { data } = useLeaderboard(
        selectedModule === "all" ? undefined : (selectedModule as any),
        selectedDepartment === "all" ? undefined : (selectedDepartment as any)
    )

    useRedirect("/modules", disableLeaderboards)
    if (disableLeaderboards) return <></>

    return (
        <div
            style={{
                position: "relative",
                width: "100%",
                height: "100%",
                backgroundColor: "#FEFBF7",
            }}>
            <div
                style={{
                    zIndex: 1,
                    top: 0,
                    left: 0,
                    opacity: 0.5,
                    backgroundImage: `url(${server()}/static/sayfr-leaderboard-bg.svg)`,
                    width: "100%",
                    height: "100%",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                }}
            />
            <div
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    zIndex: 10,
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#545656",
                }}>
                <div
                    style={{
                        backgroundColor: "white",
                        width: 800,
                        maxWidth: "90%",
                        boxShadow: "0px 4px 20px rgba(191, 189, 176, 0.16)",
                        borderRadius: 8,
                    }}>
                    <div
                        style={{
                            padding: 32,
                            paddingBottom: 0,
                            fontSize: 32,
                            textAlign: "center",
                        }}>
                        {data?.company} Leaderboard
                    </div>
                    <div
                        style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                        <select
                            value={selectedModule}
                            onChange={(e) => setSelectedModule(e.target.value)}
                            style={{
                                backgroundColor: "#F8F8FA",
                                border: "none",
                                padding: 16,
                                borderRadius: 8,
                                margin: 32,
                                width: "30%",
                                font: "inherit",
                            }}>
                            <option value="all">All Modules</option>
                            {data?.modules
                                .sort((a, b) => a.name.localeCompare(b.name))
                                .map((m) => <option value={m.id.valueOf()}>{m.name}</option>)}
                        </select>
                        <select
                            value={selectedDepartment}
                            onChange={(e) => setSelectedDepartment(e.target.value)}
                            style={{
                                backgroundColor: "#F8F8FA",
                                border: "none",
                                padding: 16,
                                borderRadius: 8,
                                margin: 32,
                                width: "30%",
                                font: "inherit",
                            }}>
                            <option value="all">All Departments</option>
                            {data?.departments
                                .sort((a, b) => a.localeCompare(b))
                                .map((m) => <option value={m}>{m}</option>)}
                        </select>
                    </div>
                    <table style={{ width: "100%", borderCollapse: "collapse" }}>
                        <thead style={{ color: "#545656", fontSize: 12 }}>
                            <td style={{ width: 50 }}>
                                <div style={{ margin: 20, color: "#545656" }}>#</div>
                            </td>
                            <td style={{ paddingLeft: 16 }}>User</td>
                            {/** TODO: Reintroduce badges later once the feature is actually ready
                            <td style={{ width: 100 }}>Badges</td>
                            */}
                            <td style={{ width: 100 }}>Score</td>
                        </thead>
                        <tbody>
                            {data?.leaders.map((l) => (
                                <UserRow
                                    key={l.id.valueOf()}
                                    user={l}
                                    skip={false}
                                    highlight={l.id === data?.you?.id}
                                />
                            ))}
                            {data?.leaders.some((l) => l.id === data?.you?.id)
                                ? null
                                : data?.you && <UserRow user={data?.you} skip={true} />}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

function UserRow({
    user,
    skip,
    highlight,
}: {
    user: GetLeaderboardLeadersDto
    skip: boolean
    highlight?: boolean
}) {
    return (
        <>
            {skip && (
                <tr>
                    <td colSpan={4}>
                        <div
                            style={{ fontWeight: 400, fontSize: 14, padding: 16, paddingLeft: 28 }}>
                            <i className="fas fa-ellipsis-v" style={{ marginRight: 32 }} />
                            Your ranking
                        </div>
                    </td>
                </tr>
            )}
            <tr
                style={{
                    borderTop: "1px solid #eee",
                    fontSize: 16,
                    fontWeight: 700,
                    backgroundColor: highlight ? "#FAF3E7" : undefined,
                }}>
                <td>
                    <div
                        style={{
                            margin: 16,
                            width: 22,
                            height: 22,
                            background:
                                user.rank === 1
                                    ? `linear-gradient(86.86deg, #ED9A1A -10.35%, #F7B959 113.95%)`
                                    : user.rank === 2
                                      ? ` linear-gradient(86.86deg, #ADADAC -10.35%, #D7D7D7 113.95%)`
                                      : user.rank === 3
                                        ? `linear-gradient(86.86deg, #ED7F1A -10.35%, #CF8846 113.95%)`
                                        : undefined,
                            color: user.rank < 4 ? "white" : "#131D22",
                            borderRadius: 16,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            fontSize: 14,
                        }}>
                        {user.rank}
                    </div>
                </td>
                <td>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            padding: 12,
                        }}>
                        <div
                            style={{
                                width: 40,
                                height: 40,
                                borderRadius: 24,
                                backgroundColor: "#F8F8FA",
                                marginRight: 12,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                alignSelf: "center",
                            }}>
                            <i className="fas fa-user" style={{ color: "#545656" }} />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                            }}>
                            <div style={{ fontWeight: 700, color: "#131D22" }}>{user.name}</div>
                            <div
                                style={{
                                    fontWeight: 400,
                                    fontSize: 14,
                                    color: "#545656",
                                    paddingTop: 4,
                                }}>
                                {user.department ?? "No department"}
                            </div>
                            {highlight && user.isAnonymous && (
                                <div
                                    style={{
                                        fontWeight: 400,
                                        fontSize: 12,
                                        color: "#545656",
                                        paddingTop: 6,
                                    }}>
                                    <em>You appear as anonymous to others</em>
                                </div>
                            )}
                        </div>
                    </div>
                </td>
                {/** TODO: Reintroduce badges later once the feature is actually ready
                 <td>
                      <div
                        style={{
                            fontWeight: 400,
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            alignSelf: "center",
                            height: "100%",
                        }}>
                        <BadgesIcon />
                        <div>{user.badges}</div>
                    </div>
                </td>
                */}
                <td>{user.score}</td>
            </tr>
        </>
    )
}

function BadgesIcon() {
    return (
        <svg
            width="13"
            height="14"
            viewBox="0 0 13 14"
            style={{ marginRight: 8 }}
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.21313 3.10734L1.21435 3.10659L4.83435 0.89119L4.8345 0.891103C5.14176 0.702966 5.52491 0.702966 5.83217 0.891103L5.83224 0.891144L9.45128 3.10654L9.45312 3.10766C9.73857 3.28128 9.91667 3.59728 9.91667 3.93982V8.08082C9.91667 8.39657 9.74303 8.71509 9.45312 8.89142L9.45116 8.89261L5.83221 11.1089C5.83218 11.1089 5.83215 11.1089 5.83212 11.109C5.52485 11.297 5.14173 11.297 4.83448 11.1089C4.83447 11.1089 4.83446 11.1089 4.83445 11.1089L1.21448 8.89256L1.21313 8.89174C0.923813 8.71545 0.75 8.39673 0.75 8.08082L0.75 3.93982C0.75 3.59713 0.928263 3.28092 1.21313 3.10734Z"
                fill="#ECC38F"
                stroke="#F8B031"
                strokeWidth="1.5"
            />
        </svg>
    )
}
