import React, { useState } from "react"
import { useHover } from "../../../packages/hooks/useHover"
import { useLocalize } from "../../../packages/localization/client-side/useLocalize"
import { Localized } from "../../../packages/localization/Localized"
import { darkMustard, peach } from "./colors"
import { ArrowLeft } from "./ExitButton"
import { Markdown } from "../../../reactor/Types/Primitives/Markdown"
import { MarkdownView } from "../../../packages/markdown-edit/MarkdownView"

export function ScoreScreen(props: {
    score: number
    scoreMax: number
    success: boolean
    choices?: { red: number; green: number; yellow: number; neutral: number }
    infoText?: Localized<Markdown>
    exit: () => void
}) {
    return (
        <div
            style={{
                zIndex: 10,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",

                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
            }}>
            <div
                style={{
                    backgroundColor: "white",
                    boxShadow: "rgb(77, 62, 38, 0.1) 0px 20px 30px -10px",
                    borderRadius: 16,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    paddingTop: 32,
                    paddingBottom: 32,
                }}>
                <div style={{ width: 640 }} />

                {props.infoText && <InfoToolbar markdown={props.infoText} />}

                {props.success ? <Success /> : <Fail />}
                <div style={{ marginTop: 32, marginBottom: 16 }}>
                    {!props.success
                        ? "You did not succeed this time."
                        : "You have completed succesfully!"}
                </div>

                {props.choices && (
                    <>
                        <div style={{ fontWeight: "bold", marginTop: 32, marginBottom: 16 }}>
                            Your interactions
                        </div>
                        <div style={{ width: 200 }}>
                            <ScoreLine name="Best" value={props.choices.green} />
                            <ScoreLine name="Good" value={props.choices.yellow} />
                            <ScoreLine name="Poor" value={props.choices.red} />
                            <ScoreLine name="Neutral" value={props.choices.neutral} />
                        </div>
                    </>
                )}
                <div style={{ margin: 24, fontSize: 18 }}>Your Score</div>
                <div style={{ width: "100%", position: "relative", height: 80, marginBottom: 8 }}>
                    <div
                        style={{
                            position: "absolute",
                            left: 0,
                            width: "12%",
                            height: 57,
                            top: -8,
                            backgroundColor: "#E78A5F",
                        }}
                    />
                    <div
                        style={{
                            position: "absolute",
                            right: 0,
                            width: "12%",
                            height: 57,
                            top: -8,
                            backgroundColor: "#E78A5F",
                        }}
                    />
                    <div
                        style={{
                            position: "absolute",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "flex-end",
                            justifyContent: "center",
                            backgroundColor: peach,
                            width: "80%",
                            left: "10%",
                            padding: 8,
                            color: "white",
                        }}>
                        <div
                            style={{
                                fontSize: 32,
                                fontWeight: "bold",
                            }}>
                            {props.score}
                        </div>
                        <div>
                            <div style={{ marginLeft: 8, fontSize: 24 }}>/ {props.scoreMax}</div>
                        </div>
                    </div>
                </div>
                <BackToMenuButton onClick={props.exit} />
            </div>
        </div>
    )
}

function ScoreLine(props: { name: string; value: number }) {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                borderBottom: "1px solid #ccc",
                marginBottom: 6,
            }}>
            <div style={{ marginBottom: 2 }}>{props.name}</div>
            <div style={{ flex: 1 }} />
            <div>{props.value}</div>
        </div>
    )
}

function Fail() {
    return (
        <svg
            width="100"
            height="100"
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M50 0C22.3828 0 0 22.3828 0 50C0 77.6172 22.3828 100 50 100C77.6172 100 100 77.6172 100 50C100 22.3828 77.6172 0 50 0ZM70.7617 67.2852C72.434 69.2598 72.1869 72.2207 70.2094 73.8926C69.3359 74.6289 68.2617 75 67.1875 75C65.857 75 64.5332 74.4353 63.6055 73.3398L50 57.2656L36.3867 73.3398C35.4688 74.4336 34.1406 75 32.6367 75C31.7383 75 30.6641 74.6289 29.7852 73.8867C27.8086 72.2144 27.5605 69.2539 29.2328 67.2793L43.8672 50L29.2383 32.7148C27.5586 30.7422 27.8125 27.7734 29.7852 26.1133C31.7578 24.4336 34.7266 24.6875 36.3867 26.6602L50 42.7344L63.6074 26.6523C65.2768 24.6758 68.2402 24.4238 70.2148 26.1C72.1914 27.7723 72.4395 30.7328 70.7672 32.7074L56.1328 50L70.7617 67.2852Z"
                fill="#E63941"
            />
        </svg>
    )
}

function Success() {
    return (
        <svg
            width="100"
            height="100"
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 50C0 22.3828 22.3828 0 50 0C77.6172 0 100 22.3828 100 50C100 77.6172 77.6172 100 50 100C22.3828 100 0 77.6172 0 50ZM72.6172 41.3672C74.7461 39.2383 74.7461 35.7617 72.6172 33.6328C70.4883 31.5039 67.0117 31.5039 64.8828 33.6328L43.75 54.7656L35.1172 46.1328C32.9883 44.0039 29.5117 44.0039 27.3828 46.1328C25.2539 48.2617 25.2539 51.7383 27.3828 53.8672L39.8828 66.3672C42.0117 68.4961 45.4883 68.4961 47.6172 66.3672L72.6172 41.3672Z"
                fill="#289259"
            />
        </svg>
    )
}

function BackToMenuButton(props: { onClick: () => void }) {
    const { hover, hoverProps } = useHover()
    return (
        <div
            onClick={props.onClick}
            {...hoverProps}
            style={{
                padding: 16,
                borderRadius: 8,
                backgroundColor: "#FDE7C1",
                display: "flex",
                flexDirection: "row",
                justifyItems: "center",
                transform: hover ? "scale(1.05)" : "scale(1)",
                transition: "transform 0.2s",
                cursor: "pointer",
            }}>
            <ArrowLeft hover={false} fill={darkMustard} />
            Back to menu
        </div>
    )
}

function InfoToolbar(props: { markdown: Localized<Markdown> }) {
    const [showPopup, setShowPopup] = useState(false)
    const localize = useLocalize()

    return (
        <div
            style={{
                width: 420,
                alignSelf: "flex-end",
                marginRight: 32,
                marginBottom: 24,
            }}>
            {showPopup && (
                <div
                    style={{
                        width: 420,
                        position: "fixed",
                        zIndex: 1,
                        backgroundColor: "white",
                        boxShadow: "0px 4px 20px rgba(191, 189, 176, 0.16)",
                        borderRadius: 12,
                    }}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                        }}>
                        <div
                            style={{
                                flex: 1,
                                paddingLeft: 24,
                                paddingTop: 24,
                                paddingBottom: 24,
                            }}>
                            <div style={{ fontWeight: 700, fontSize: 18 }}>
                                How is the score calculated?
                            </div>
                            <div
                                style={{
                                    fontWeight: 400,
                                    fontSize: 18,
                                    marginTop: 10,
                                }}>
                                <MarkdownView value={props.markdown} />
                            </div>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor: "#FDE7C1",
                                borderRadius: 12,
                                width: 40,
                                height: 40,
                                cursor: "pointer",
                            }}
                            onClick={() => setShowPopup(false)}>
                            <Cross />
                        </div>
                    </div>
                </div>
            )}
            <span
                onClick={() => setShowPopup(true)}
                style={{
                    cursor: "pointer",
                    fontSize: 18,
                    fontWeight: 400,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-end",
                }}>
                Score calculation
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "#FDE7C1",
                        borderRadius: 12,
                        marginLeft: 8,
                        width: 40,
                        height: 40,
                    }}>
                    <Info />
                </div>
            </span>
        </div>
    )
}

function Info() {
    return (
        <svg
            width="9"
            height="22"
            viewBox="0 0 9 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.875 19.8H5.625V7.6997C5.625 7.0924 5.12109 6.59968 4.5 6.59968H2.625C2.00391 6.59968 1.5 7.09469 1.5 7.6997C1.5 8.30472 2.00391 8.79973 2.625 8.79973H3.375V19.8H1.125C0.503906 19.8 0 20.295 0 20.9C0 21.505 0.503906 22 1.125 22H7.875C8.49609 22 9 21.5073 9 20.9C9 20.2927 8.49844 19.8 7.875 19.8ZM4.5 4.39963C5.74266 4.39963 6.75 3.41465 6.75 2.19959C6.75 0.984521 5.74219 0 4.5 0C3.25781 0 2.25 0.984979 2.25 2.20005C2.25 3.41511 3.25734 4.39963 4.5 4.39963Z"
                fill="#E69A1C"
            />
        </svg>
    )
}

function Cross() {
    return (
        <svg
            width="22"
            height="23"
            viewBox="0 0 22 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M21.6778 20.8217C22.1665 21.3317 22.0904 22.0907 21.5095 22.5188C21.2481 22.7091 20.9388 22.7994 20.6237 22.7994C20.2316 22.7994 19.8423 22.6536 19.5702 22.3697L10.9994 13.4277L2.42913 22.3677C2.15587 22.6539 1.76631 22.7994 1.37389 22.7994C1.0615 22.7994 0.748307 22.7069 0.490513 22.5171C-0.0903849 22.0889 -0.166578 21.3294 0.322201 20.8199L9.20627 11.5554L0.322373 2.29105C-0.166348 1.78105 -0.0902703 1.02208 0.490684 0.5939C1.07084 0.164718 1.93875 0.230476 2.42798 0.740977L10.9994 9.68302L19.5696 0.742984C20.0593 0.232483 20.9256 0.167228 21.5065 0.595506C22.0874 1.02378 22.1636 1.78316 21.6748 2.29266L12.7925 11.5554L21.6778 20.8217Z"
                fill="#E69A1C"
            />
        </svg>
    )
}
