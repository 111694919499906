import React, { SyntheticEvent, useState } from "react"
import { useLocalize } from "../../../packages/localization/client-side/useLocalize"
import { Localized } from "../../../packages/localization/Localized"
import { Markdown } from "../../../reactor/Types/Primitives"
import { StepCardButton, StepCardTimeline } from "./ModuleStepCard"

export function FacilitatorStepBox(props: {
    title: Localized<string>
    description?: Localized<Markdown>
    onClick: () => void
    icon: JSX.Element
    isLast: boolean
    color: string
    disabled?: boolean
    selected?: boolean
    article?: boolean
}) {
    const localize = useLocalize()
    return (
        <div
            onClick={props.disabled ? undefined : props.onClick}
            style={{
                cursor: props.disabled ? undefined : "pointer",
                display: "flex",
                flexDirection: "row",
                margin: 24,
            }}>
            <StepCardTimeline {...props} />
            <div
                style={{
                    borderRadius: 16,
                    boxShadow: "0px 4px 20px 0px #BFBDB029",
                    padding: 24,
                    backgroundColor: "white",
                    flex: 1,
                    border: "1px solid #FAF3E7",
                    opacity: props.disabled ? 0.5 : 1,
                    display: "flex",
                    flexDirection: "column",
                }}>
                <div style={{ fontWeight: "bold", fontSize: 20, marginBottom: 8 }}>
                    {localize(props.title)}
                </div>
                <div>{props.description && localize(props.description)}</div>
                {props.article ? (
                    <StepCardButton disabled={false} onClick={props.onClick} text="Click to read" />
                ) : (
                    <Checkbox
                        onClick={props.onClick}
                        disabled={props.disabled}
                        selected={props.selected}
                        text={props.selected ? "Completed" : "Not started"}
                        style={{ opacity: 1 }}
                    />
                )}
            </div>
        </div>
    )
}

export function ActionCardBox(props: {
    title: string
    description: Markdown
    onSelect: (selected: boolean) => void
    onTitleChanged?: (value: string) => void
    onDescriptionChanged?: (value: Markdown) => void
    selected?: boolean
    selectedColor: string
    canEdit?: boolean
    onRemove?: () => void
}) {
    const [selected, _setSelected] = useState(props.selected ?? false)
    const [title, _setTitle] = useState(props.title)
    const [description, _setDescription] = useState(props.description)
    const [edit, setEdit] = useState(false)

    const setSelected = (value: boolean) => {
        _setSelected(value)
        props.onSelect?.(value)
    }

    const setTitle = (value: string) => {
        _setTitle(value)
        props.onTitleChanged?.(value)
    }

    const setDescription = (value: Markdown) => {
        _setDescription(value)
        props.onDescriptionChanged?.(value)
    }

    const titlePlaceholder = "Create Custom Card"
    const descriptionPlaceholder = "Create a custom Action Card for this session..."
    const canSelect = title.length > 0 && description.length > 0

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: 12,
                marginRight: 12,
            }}>
            <div
                style={{
                    borderRadius: 16,
                    boxShadow: "0px 4px 20px 0px #BFBDB029",
                    padding: 24,
                    backgroundColor: selected ? props.selectedColor : "white",
                    flex: 1,
                    border: "1px solid #FAF3E7",
                    display: "flex",
                    flexDirection: "column",
                }}
                onClick={(e) => {
                    if (props.canEdit) setEdit(!edit)
                }}>
                <div
                    style={{
                        color: selected ? "white" : undefined,
                        cursor: props.canEdit ? "pointer" : undefined,
                        fontWeight: "bold",
                        fontSize: 20,
                        marginBottom: 8,
                    }}>
                    {edit ? (
                        <input
                            type="text"
                            value={title}
                            placeholder={titlePlaceholder}
                            maxLength={28}
                            style={{
                                width: "100%",
                                backgroundColor: selected ? props.selectedColor : "white",
                                color: selected ? "white" : undefined,
                                border: "1px solid #FAF3E7",
                                fontWeight: 600,
                                fontSize: 20,
                            }}
                            onChange={(e) => setTitle(e.target.value)}
                            onClick={(e) => e.stopPropagation()}
                        />
                    ) : (
                        textOrUndefined(title) ?? titlePlaceholder
                    )}
                </div>
                <div
                    style={{
                        color: selected ? "white" : "#545656",
                        cursor: props.canEdit ? "pointer" : undefined,
                    }}>
                    {edit ? (
                        <textarea
                            value={description.valueOf()}
                            placeholder={descriptionPlaceholder}
                            maxLength={200}
                            style={{
                                minWidth: "100%",
                                backgroundColor: selected ? props.selectedColor : "white",
                                color: selected ? "white" : undefined,
                                border: "1px solid #FAF3E7",
                                fontWeight: 400,
                                fontSize: 16,
                            }}
                            onChange={(e) => setDescription(e.target.value as any as Markdown)}
                            onClick={(e) => e.stopPropagation()}
                        />
                    ) : (
                        textOrUndefined(description.valueOf()) ?? descriptionPlaceholder
                    )}
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <Checkbox
                        onClick={(e) => {
                            if (edit || !canSelect) return
                            e.stopPropagation()
                            setSelected(!selected)
                        }}
                        disabled={!canSelect}
                        selected={selected}
                        selectedColor={"white"}
                    />
                    {props.canEdit && props.onRemove && (
                        <div
                            style={{
                                cursor: "pointer",
                                marginTop: 24,
                                marginLeft: 24,
                                color: props.selected ? "white" : "#545656",
                            }}
                            onClick={(e) => {
                                if (edit) return
                                e.stopPropagation()
                                props.onRemove?.()
                            }}>
                            Remove
                        </div>
                    )}
                </div>
            </div>
        </div>
    )

    function textOrUndefined(text: string) {
        return text.length > 0 ? text : undefined
    }
}

function Checkbox(props: {
    disabled?: boolean
    selected?: boolean
    selectedColor?: string
    onClick: (e: SyntheticEvent) => void
    text?: string
    style?: React.CSSProperties
}) {
    return (
        <div
            onClick={props.disabled ? undefined : props.onClick}
            style={{
                marginTop: 24,
                color: props.selected
                    ? props.selectedColor ?? "#545656"
                    : props.disabled
                    ? "#333"
                    : "#545656",
                opacity: props.disabled ? 0.5 : 1,
                alignSelf: "flex-start",
                cursor: props.disabled ? undefined : "pointer",
                display: "flex",
                alignItems: "center",
                fontWeight: "normal",
                fontSize: 16,
                ...props.style,
            }}>
            {props.selected ? (
                <svg
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <rect y="0.5" width="16" height="16" rx="3" fill="white" />
                    <svg
                        width="11"
                        height="8"
                        x="3"
                        y="4.5"
                        viewBox="0 0 11 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M10.7698 0.230583C11.0767 0.537536 11.0767 1.03357 10.7698 1.34053L4.48373 7.62693C4.1768 7.93388 3.68079 7.93388 3.37385 7.62693L0.230129 4.48373C-0.0767097 4.17677 -0.0767097 3.68074 0.230129 3.37378C0.537017 3.06683 1.0345 3.06683 1.34144 3.37378L3.90669 5.95956L9.65991 0.230583C9.96685 -0.0768611 10.4629 -0.0768611 10.7698 0.230583Z"
                            fill="black"
                        />
                    </svg>
                </svg>
            ) : (
                <svg
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <rect
                        x="1"
                        y="1.5"
                        width="14"
                        height="14"
                        rx="2"
                        stroke="#545656"
                        strokeWidth="2"
                    />
                </svg>
            )}
            <div style={{ marginLeft: 8 }}>
                {props.text ?? (props.selected ? "Selected" : "Select")}
            </div>
        </div>
    )
}
