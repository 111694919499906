import React from "react"
import { Uuid } from "../../../reactor/Types/Primitives"
import { server } from "../../../server"
import { RButton } from "../../../studio/Views/Buttons"
import { GetSimulationDto, GetSimulationScenesDto, StepDto } from "./client"
import { StepView } from "./StepView"

export function FeedbackToolbar({
    paused,
    pause,
    resume,
    goNext,
    goBack,
    canNavigate,
    steps,
    sim,
    choices,
    openFeedbackBox,
    evaluateCondition,
}: {
    paused: boolean
    pause: () => void
    resume: () => void
    goNext: () => void
    goBack: (to: StepDto) => void
    canNavigate: boolean
    steps: StepDto[]
    sim: GetSimulationDto
    choices: Uuid<"DialogOption">[]
    openFeedbackBox: () => void
    evaluateCondition: (s: string) => any
}) {
    const sceneBoxes: { scene: GetSimulationScenesDto; steps: StepDto[] }[] = []

    steps.forEach((step) => {
        const scene = sim.scenes.find((x) => x.steps.some((y) => y.id === step.id))
        if (!scene) {
            console.log("Step not found in any scene", step)
            return
        }
        if (sceneBoxes.length === 0 || sceneBoxes[sceneBoxes.length - 1].scene !== scene) {
            sceneBoxes.push({ scene, steps: [step] })
        } else {
            sceneBoxes[sceneBoxes.length - 1].steps.push(step)
        }
    })

    return (
        <>
            <div
                style={{
                    justifySelf: "flex-start",
                    display: "flex",
                    flexDirection: "row",
                    maxHeight: 48,
                    backgroundColor: "#0009",
                    padding: 8,
                    alignItems: "center",
                }}>
                <img
                    style={{ width: 150, marginLeft: 16, objectFit: "contain" }}
                    src={`${server()}/static/sayfr.png`}
                />
                <div style={{ marginLeft: 16, color: "#fff", alignContent: "center" }}>
                    Content feedback utility
                </div>
                <div style={{ flex: 1 }} />

                <RButton
                    onClick={() => {
                        if (!paused) {
                            pause()
                        } else {
                            resume()
                        }
                    }}
                    style={{ justifySelf: "flex-end", marginLeft: 8 }}
                    icon={paused ? "play" : "pause"}
                    disabled={canNavigate}
                    variant="primary">
                    {paused ? "Play" : "Pause"}
                </RButton>

                <RButton
                    onClick={goNext}
                    style={{ justifySelf: "flex-end", marginLeft: 8 }}
                    icon="forward"
                    disabled={!!canNavigate}
                    variant="primary">
                    Next
                </RButton>

                <RButton
                    onClick={() => {
                        pause()
                        openFeedbackBox()
                    }}
                    style={{ justifySelf: "flex-end", marginLeft: 8 }}
                    icon="comment"
                    variant="primary">
                    Give feedback
                </RButton>
            </div>
            <div
                style={{
                    background: "#444",
                    overflowX: "auto",
                    display: "flex",
                    flexDirection: "row",
                    paddingBottom: 8,
                    minHeight: 200,
                }}>
                {sceneBoxes.map((sb, i) => (
                    <div
                        style={{
                            backgroundColor: i % 2 ? "#fff2" : undefined,
                            marginLeft: 8,
                            marginRight: 8,
                            paddingLeft: 8,
                            paddingRight: 8,
                            paddingTop: 4,
                        }}>
                        <div style={{ color: "white", marginBottom: 4 }}>{sb.scene.name}</div>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <div style={{ marginLeft: 4, marginRight: 4 }}>
                                {sb.scene.stillImage && (
                                    <img style={{ height: 120 }} src={sb.scene.stillImage} />
                                )}
                            </div>
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                {sb.steps.map((s) => (
                                    <StepView
                                        onClick={() => goBack(s)}
                                        step={s}
                                        characters={sim.characters}
                                        isCurrent={steps[steps.length - 1] === s}
                                        choices={choices}
                                        evaluateScript={evaluateCondition}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}
