import React from "react"
import { useDirtyContext } from "../../packages/editing/DirtyContext"
import { prettyCamel } from "../../reactor/Helpers"
import {
    GetTypeProps,
    IsArrayType,
    Property,
    IsUnionType,
    IsNumberType,
} from "../../reactor/Types/Type"
import { ToolButton } from "./ToolButton"

export function GridView({
    buttons,
    label,
    property,
    obj,
    hideBadge,
    isRoot,
}: {
    buttons: ToolButton[]
    property: Property
    label?: string
    obj: any
    hideBadge?: boolean
    isRoot: boolean
}) {
    const { type, name, tags, description } = property
    if (!IsArrayType(type)) throw new Error("GridView must be used with an array type")

    const { setDirty } = useDirtyContext()

    const arr = obj[name]

    const props = GetTypeProps(type.array)

    return (
        <div>
            <table style={{ width: "100%" }}>
                <thead>
                    <tr>
                        {props.map((p) => (
                            <td>{prettyCamel(p.name)}</td>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {arr.map((row: any) => (
                        <tr>
                            {props.map((p) => {
                                if (IsUnionType(p.type)) {
                                    return (
                                        <td>
                                            <select
                                                className="form-control"
                                                value={row[p.name]}
                                                onChange={(e) => {
                                                    row[p.name] = e.target.value
                                                    setDirty()
                                                }}>
                                                {p.type.union.map((u) => (
                                                    <option value={(u as any).string}>
                                                        {(u as any).string}
                                                    </option>
                                                ))}
                                            </select>
                                        </td>
                                    )
                                }

                                return (
                                    <td>
                                        <input
                                            className="form-control"
                                            type="text"
                                            value={row[p.name]}
                                            onChange={(e) => {
                                                if (IsNumberType(p.type)) {
                                                    row[p.name] = parseFloat(e.target.value)
                                                } else {
                                                    row[p.name] = e.target.value
                                                }
                                                setDirty()
                                            }}
                                        />
                                    </td>
                                )
                            })}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}
