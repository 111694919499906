import interpolate from "color-interpolate"
import React from "react"
import { useHover } from "../../../packages/hooks/useHover"
import { useLocalize } from "../../../packages/localization/client-side/useLocalize"
import { Localized } from "../../../packages/localization/Localized"
import { Modal } from "../../../packages/modal/Modal"
import { Markdown } from "../../../reactor/Types/Primitives"
import { correctGreen, darkMustard } from "./colors"

export function ModuleStepBox(props: {
    title: Localized<string>
    description?: Localized<Markdown>
    onClick: (startFromSave: boolean) => void
    isFacilitatorStep?: boolean
    icon: JSX.Element
    isLast: boolean
    color: string
    disabled: boolean
    nextDisabled: boolean
    score: number
    maxScore: number
    savedGame?: { sceneName: string; image?: any }
    buttonText?: string
    minutesToComplete?: number
}) {
    const localize = useLocalize()
    const onClick = async () => {
        if (!props.disabled) {
            const modalResult = props.savedGame
                ? await Modal<boolean>((close) => (
                      <div
                          style={{
                              backgroundColor: "white",
                              borderRadius: 8,
                              maxWidth: 400,
                              padding: 24,
                              display: "flex",
                              flexDirection: "column",
                          }}>
                          <img
                              src={props.savedGame?.image}
                              style={{
                                  width: 128,
                                  height: 128,
                                  borderRadius: 128,
                                  objectFit: "cover",
                                  alignSelf: "center",
                                  marginBottom: 32,
                                  border: `3px solid ${darkMustard}`,
                              }}
                          />
                          <div>
                              You left <b>{props.savedGame?.sceneName}</b>. Would you like to
                              continue from your last save, or start from the beginning?
                          </div>
                          <StepCardButton
                              disabled={false}
                              onClick={() => close(true)}
                              text="Continue last game"
                          />
                          <StepCardButton
                              disabled={false}
                              onClick={() => close(false)}
                              text="Start from beginning"
                          />
                      </div>
                  ))
                : false
            if (modalResult !== undefined) props.onClick(modalResult)
        }
    }
    return (
        <div
            onClick={onClick}
            style={{
                cursor: props.disabled ? undefined : "pointer",
                display: "flex",
                flexDirection: "row",
                margin: 24,
            }}>
            <StepCardTimeline {...props} />
            <div
                style={{
                    borderRadius: 16,
                    boxShadow: "0px 4px 20px 0px #BFBDB029",
                    padding: 24,
                    backgroundColor: "white",
                    flex: 1,
                    border: "1px solid #FAF3E7",
                    opacity: props.disabled ? 0.5 : 1,
                    display: "flex",
                    flexDirection: "column",
                }}>
                <div style={{ fontWeight: "bold", fontSize: 20, marginBottom: 8 }}>
                    {localize(props.title)}
                </div>
                <div>{props.description && localize(props.description)}</div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <StepCardButton
                        onClick={onClick}
                        disabled={props.disabled}
                        text={
                            props.buttonText ??
                            (props.score
                                ? `Score: ${props.score} / ${props.maxScore}`
                                : props.disabled
                                ? "Not available yet"
                                : "Click to start")
                        }
                    />
                    {props.minutesToComplete && (
                        <div style={{ flex: 1, textAlign: "right", marginTop: 32 }}>
                            {props.minutesToComplete} min
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export function StepCardTimeline(props: {
    icon: JSX.Element
    isLast: boolean
    color: string
    disabled?: boolean
}) {
    const disabledColor = interpolate([props.color, "#fff"])(0.5)
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
            }}>
            <div
                style={{
                    width: 64,
                    height: 64,
                    backgroundColor: props.disabled ? disabledColor : props.color,
                    margin: 16,
                    borderRadius: 64,
                    border: "4px solid white",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                    zIndex: 1,
                }}>
                {props.icon}
                {!props.isLast && (
                    <div
                        style={{
                            position: "absolute",
                            top: 60,
                            height: 160,
                            width: 4,
                            backgroundColor: props.disabled ? disabledColor : props.color,
                        }}
                    />
                )}
                <div
                    style={{
                        width: 64,
                        height: 64,
                        margin: 16,
                        borderRadius: 64,
                        border: "4px solid white",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "absolute",
                        zIndex: 1,
                    }}></div>
            </div>
        </div>
    )
}

export function StepCardButton(props: {
    disabled: boolean
    onClick: () => void
    text: string
    image?: string
}) {
    const { hover, hoverProps } = useHover()
    return (
        <div
            {...hoverProps}
            onClick={props.onClick}
            style={{
                marginTop: 32,
                fontWeight: "bold",
                color: props.disabled ? "#333" : hover ? "#4D4" : correctGreen,
                transform: hover ? `scale(1.01)` : undefined,
                transition: "all 0.2s ease",
                alignSelf: "flex-start",
                cursor: props.disabled ? undefined : "pointer",
                display: "flex",
                alignItems: "center",
            }}>
            {props.image && (
                <img
                    src={props.image}
                    style={{
                        width: 64,
                        height: 64,
                        borderRadius: 32,
                        objectFit: "cover",
                        marginRight: 24,
                    }}
                />
            )}
            {props.text}
        </div>
    )
}
