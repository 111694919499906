import React, { useState } from "react"
import { useDirtyContext } from "../../packages/editing/DirtyContext"
import { Property } from "../../reactor/Types/Type"
import { useDocumentPropertyDeclarations } from "../client"
import { RButton } from "./Buttons"
import { CodeEdit } from "./CodeEdit"
import { useDocumentContext } from "./DocumentContext"
import { PropRow } from "./PropRow"

export function TypeScriptView({
    obj,
    property,
    label,
}: {
    obj: any
    label?: string
    property: Property
}) {
    const [editing, setEditing] = useState(false)
    return (
        <PropRow
            buttons={[]}
            badge={
                <RButton
                    variant="secondary"
                    icon={editing ? "check" : "pencil"}
                    onClick={() => setEditing(!editing)}>
                    {editing ? "Done" : "Edit"}
                </RButton>
            }
            description={property.description}
            label={label}>
            {editing ? (
                <TypeScriptViewInner obj={obj} property={property} />
            ) : (
                <pre style={{ backgroundColor: "#f8f8f8", padding: 16 }}>{obj[property.name]}</pre>
            )}
        </PropRow>
    )
}

function TypeScriptViewInner({ obj, property }: { obj: any; property: Property }) {
    const declarationsFunc = property.tags?.declarations
    const docContext = useDocumentContext()
    const { setDirty } = useDirtyContext()

    const { data: declarations } = useDocumentPropertyDeclarations(
        !!docContext && !!declarationsFunc
            ? {
                  document: docContext.doc,
                  object: obj !== docContext.doc ? obj : null,
                  declarationsFunc,
                  includeMutations: property.tags?.includeMutations,
              }
            : null
    )
    return (
        <CodeEdit
            text={obj[property.name]}
            declarations={declarations?.code || ""}
            language="typescript"
            onEdited={(code) => {
                obj[property.name] = code
                setDirty()
            }}
        />
    )
}
