import React from "react"

export function CheckCircle() {
    return (
        <svg
            width="100"
            height="100"
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 50C0 22.3828 22.3828 0 50 0C77.6172 0 100 22.3828 100 50C100 77.6172 77.6172 100 50 100C22.3828 100 0 77.6172 0 50ZM72.6172 41.3672C74.7461 39.2383 74.7461 35.7617 72.6172 33.6328C70.4883 31.5039 67.0117 31.5039 64.8828 33.6328L43.75 54.7656L35.1172 46.1328C32.9883 44.0039 29.5117 44.0039 27.3828 46.1328C25.2539 48.2617 25.2539 51.7383 27.3828 53.8672L39.8828 66.3672C42.0117 68.4961 45.4883 68.4961 47.6172 66.3672L72.6172 41.3672Z"
                fill="#289259"
            />
        </svg>
    )
}
