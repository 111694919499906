import React, { useContext, useState } from "react"
import { useDirtyContext } from "../../../packages/editing/DirtyContext"
import { RButton } from "../../../studio/Views/Buttons"
import { CodeEdit } from "../../../studio/Views/CodeEdit"
import { PropView } from "../../../studio/Views/PropView"
import { GetSimulationDto, postSsml, SayDto, useSimulation } from "../app/client"
import { defaults as studioDefaults } from "../../../studio/client"
import { useDocumentContext } from "../../../studio/Views/DocumentContext"
import { server } from "../../../server"
import { ObjectContext } from "../../../studio/Views/ObjectContext"
import { PropRow } from "../../../studio/Views/PropRow"
import { ToolButton } from "../../../studio/Views/ToolButton"
import { Localized } from "../../../packages/localization/Localized"

let audio: HTMLAudioElement | undefined

export function SSMLEditor({
    obj,
    prop,
    label,
    description,
    buttons,
}: {
    obj: any
    prop: string
    label: string
    description: Localized<string>
    buttons: ToolButton[]
}) {
    const { setDirty } = useDirtyContext()
    const doc: GetSimulationDto = useDocumentContext()?.doc
    const { data: sim } = useSimulation(doc.id)

    const say: SayDto | undefined = useContext(ObjectContext)?.obj
    const char = say && sim?.characters.find((c) => c.id === say.character)
    const voice = char?.voice

    const [playState, setPlayState] = useState<"idle" | "synthesizing" | "playing">("idle")

    async function playOrStop() {
        if (playState !== "idle") {
            if (audio) {
                audio.pause()
                setPlayState("idle")
                audio = undefined
                return
            }
        }
        if (!voice) throw new Error("Voice not loaded yet")
        setPlayState("synthesizing")
        try {
            const res = await postSsml({ ssml: obj[prop], voice }, studioDefaults.headers)
            setPlayState("playing")

            audio = new Audio(`${server()}/api/ssml/${res.blobHandle}`)
            audio.addEventListener("ended", () => setPlayState("idle"))
            void audio.play()
        } catch (e: any) {
            alert(e.detail)
            setPlayState("idle")
        }
    }
    const value = obj[prop]

    return (
        <PropRow description={description} label={label} buttons={buttons ?? []}>
            {value !== undefined ? (
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ flex: 1 }}>
                        <CodeEdit
                            language="xml"
                            declarations=""
                            text={obj[prop]}
                            onEdited={(newText) => {
                                obj[prop] = newText
                                setDirty()
                            }}
                        />
                    </div>
                    <div style={{ width: 140 }}>
                        <RButton
                            icon={playState === "idle" ? "play" : "stop"}
                            onClick={playOrStop}
                            style={{ alignSelf: "flex-start" }}
                            variant="secondary">
                            {playState === "idle"
                                ? "Play"
                                : playState === "synthesizing"
                                ? "Synthesizing"
                                : "Playing..."}
                        </RButton>
                    </div>
                </div>
            ) : (
                <RButton
                    variant="secondary"
                    onClick={() => {
                        obj[prop] = ""
                        setDirty()
                    }}>
                    Add
                </RButton>
            )}
        </PropRow>
    )
}

PropView.componentOverride.set("SSML", SSMLEditor)
