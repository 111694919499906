import React from "react"
import { darkMustard } from "./colors"

export function ModalButton(props: {
    onClick: (result: unknown) => void
    children: React.ReactNode
    disabled?: boolean
    color?: string
}) {
    return (
        <div
            style={{
                cursor: "pointer",
                backgroundColor: props.color ?? darkMustard,
                opacity: props.disabled ? 0.5 : 1,
                color: "white",
                textAlign: "center",
                padding: 20,
                borderRadius: 12,
                width: 300,
                fontSize: 18,
                margin: "0 auto",
                marginTop: 36,
            }}
            onClick={props.disabled ? undefined : props.onClick}>
            {props.children}
        </div>
    )
}

export function SaveAndCloseButton(props: { onClick: (result: unknown) => void; text?: string }) {
    return (
        <div
            style={{
                cursor: "pointer",
                backgroundColor: darkMustard,
                color: "white",
                textAlign: "center",
                padding: 14,
                borderRadius: 12,
                width: 400,
                fontSize: 18,
                fontWeight: 700,
                margin: "64 auto",
            }}
            onClick={props.onClick}>
            {props.text ?? "SAVE AND CLOSE"}
        </div>
    )
}
