import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { EditableArray } from "../../../packages/editing/EditableArray"
import { EditableImage } from "../../../packages/editing/EditableImage"
import { EditableObject } from "../../../packages/editing/EditableObject"
import { EditableText } from "../../../packages/editing/EditableText"
import { EditableSections } from "../../../packages/editing/EditableSections"
import { useHover } from "../../../packages/hooks/useHover"
import { useNavigate } from "../../../packages/hooks/useNavigate"
import { ImageToCSS } from "../../../reactor/Types/File"
import { Uuid } from "../../../reactor/Types/Primitives"
import {
    CarouselCard,
    CarouselSection,
    ImageSection,
    InfoBoxSection,
    MarkdownSection,
    putReadArticle,
    QuoteSection,
    useArticle,
    VideoSection,
} from "./client"
import { yellowBG } from "./colors"
import { ExitButton } from "./ExitButton"
import { ColorStyles } from "../../../packages/ui"
import { Section } from "../../../packages/editing/Section"
import { EditableVideo } from "../../../packages/editing/EditableVideo"

export function ArticlePage() {
    const { id } = useParams<{ id: string }>()
    return <ArticleView id={id as any as Uuid<"Article">} />
}

export function ArticleView(props: { id: Uuid<"Article"> }) {
    const { data } = useArticle(props.id, true)
    const navigate = useNavigate()

    useEffect(() => {
        if (data) void putReadArticle(data.article.id)
    }, [data])

    if (!data) return <></>

    const { article, author } = data

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                paddingTop: 64,
                paddingBottom: 100,
                backgroundColor: yellowBG,
            }}>
            <ExitButton text="Back" onClick={() => navigate(-1)} />
            <div className="container">
                <EditableText
                    isLocalized={true}
                    obj={article}
                    prop="title"
                    style={{ fontSize: 48, fontWeight: 600, textAlign: "center", marginBottom: 32 }}
                />
                <div className="row justify-content-center">
                    <div style={{ maxWidth: 720 }}>
                        <EditableText
                            obj={article}
                            isLocalized={true}
                            prop="ingress"
                            style={{
                                fontWeight: 400,
                                textAlign: "center",
                                alignSelf: "center",
                            }}
                        />
                    </div>
                </div>

                <div className="row justify-content-center">
                    <EditableImage
                        obj={article}
                        prop="image"
                        options={{ height: 500 }}
                        style={{
                            height: 500,
                            maxWidth: 1024,
                            width: "95%",
                            marginTop: 32,
                            marginBottom: 32,
                            backgroundPosition: "center",
                        }}
                    />
                </div>

                <EditableSections
                    sections={article.body}
                    direction="column"
                    sectionTypeName="ArticleSection"
                    itemStyle={(i) => ({ width: "100%", display: "flex", flexDirection: "column" })}
                />
            </div>
            {author && (
                <div
                    style={{
                        width: "100%",
                        maxWidth: 720,
                        alignSelf: "center",
                        margin: 32,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                    }}>
                    <div
                        style={{
                            width: 48,
                            height: 48,
                            borderRadius: 24,
                            backgroundColor: "#ddd",
                            marginRight: 16,
                            backgroundImage:
                                author?.avatar?.image && ImageToCSS(author.avatar.image),
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                        }}
                    />
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <div style={{ fontWeight: 500 }}>{author?.displayName}</div>
                        <EditableText
                            obj={article}
                            prop="authorTitle"
                            isLocalized={true}
                            style={{ fontWeight: 400 }}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}

Section(Markdown)
function Markdown(section: MarkdownSection) {
    return (
        <div className="row justify-content-center">
            <div style={{ maxWidth: 720 }}>
                <EditableText
                    obj={section}
                    isLocalized={true}
                    prop="body"
                    isMarkdown={true}
                    style={{
                        fontWeight: 400,
                        minHeight: 28,
                        marginTop: 32,
                        fontSize: 18,
                        fontFamily: "Montserrat",
                        lineHeight: 1.4,
                    }}
                />
            </div>
        </div>
    )
}

Section(Image)
function Image(section: ImageSection) {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                justifyItems: "center",
                alignItems: "center",
                width: "100%",
                marginTop: 32,
                marginBottom: 32,
            }}>
            <EditableImage
                obj={section}
                prop="image"
                options={{ height: 516 }}
                style={{
                    minHeight: 516,
                    maxWidth: 720,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                }}
            />
            <EditableText
                obj={section}
                prop="caption"
                style={{ fontSize: 14, fontStyle: "italic" }}
            />
        </div>
    )
}

Section(Video)
function Video(section: VideoSection) {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                justifyItems: "center",
                alignItems: "center",
                width: "100%",
                marginTop: 32,
                marginBottom: 32,
            }}>
            <EditableVideo
                obj={section}
                prop="video"
                posterProp="thumbnail"
                posterOptions={{ height: 516 }}
                style={{
                    height: 516,
                    maxWidth: 720,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                }}
            />
            <EditableText
                obj={section}
                prop="caption"
                style={{ fontSize: 14, fontStyle: "italic" }}
            />
        </div>
    )
}

Section(Quote)
function Quote(section: QuoteSection) {
    return (
        <div className="row justify-content-center">
            <div
                className="col"
                style={{
                    borderLeft: "2px solid #E69A1C",
                    marginTop: 32,
                    marginBottom: 32,
                    maxWidth: 720,
                }}>
                <EditableText
                    obj={section}
                    isLocalized={true}
                    prop="quote"
                    style={{
                        padding: 16,
                        paddingLeft: 24,
                        fontSize: 24,
                        fontStyle: "italic",
                    }}
                />
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        padding: 16,
                        fontSize: 16,
                    }}>
                    {" "}
                    —
                    <EditableText
                        obj={section}
                        prop="attribution"
                        isLocalized={true}
                        style={{ marginLeft: 8 }}
                    />
                </div>
            </div>
        </div>
    )
}

Section(InfoBox)
function InfoBox(section: InfoBoxSection) {
    return (
        <div className="row justify-content-center">
            <div
                style={{
                    backgroundColor: "#FAF6F1",
                    fontWeight: 400,
                    width: "100%",
                    maxWidth: 720,
                    margin: 32,
                    alignSelf: "center",
                    fontSize: 18,
                    fontFamily: "Montserrat",
                    lineHeight: 1.4,
                    borderRadius: 16,
                }}>
                <EditableText
                    obj={section}
                    isLocalized={true}
                    prop="body"
                    isMarkdown={true}
                    style={{ padding: 32 }}
                />
            </div>
        </div>
    )
}

Section(Carousel)
function Carousel(section: CarouselSection) {
    const [current, setCurrent] = useState(0)
    const wrap = !!section.wrapAround

    return (
        <div className="row justify-content-center">
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    maxWidth: 720,
                    marginTop: 32,
                    marginBottom: 32,
                }}>
                <div
                    style={{
                        width: 720,
                        maxWidth: "100%",
                        height: 516,
                        position: "relative",
                    }}>
                    <EditableArray
                        arr={section.cards}
                        direction="row"
                        itemTypeName="CarouselCard"
                        itemStyle={(i) => ({
                            position: "absolute",
                            top: 0,
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            transform:
                                i < current
                                    ? "translateX(-100%)"
                                    : i > current
                                      ? "translateX(100%)"
                                      : undefined,
                            opacity: i === current ? 1 : 0,
                            transition: "all 0.425s ease",
                        })}>
                        {(card, i) => (
                            <>
                                {(wrap || i > 0) && (
                                    <CarouselButton
                                        side="left"
                                        onClick={() =>
                                            setCurrent(
                                                current === 0
                                                    ? section.cards.length - 1
                                                    : current - 1
                                            )
                                        }
                                    />
                                )}
                                <EditableObject
                                    obj={card}
                                    actions={{}}
                                    typeName="CarouselCard"
                                    style={{ width: "100%" }}>
                                    <CarouselCardView card={card} />
                                </EditableObject>
                                {(wrap || i < section.cards.length - 1) && (
                                    <CarouselButton
                                        side="right"
                                        onClick={() =>
                                            setCurrent(
                                                current === section.cards.length - 1
                                                    ? 0
                                                    : current + 1
                                            )
                                        }
                                    />
                                )}
                            </>
                        )}
                    </EditableArray>
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        marginTop: 16,
                    }}>
                    {new Array(section.cards.length).fill(null).map((x, i) => (
                        <div
                            style={{
                                width: 8,
                                height: 8,
                                borderRadius: 4,
                                transition: "all 0.35s ease",
                                backgroundColor:
                                    i === current ? ColorStyles.gray[800] : ColorStyles.gray[300],
                                margin: 5,
                                marginTop: 32,
                            }}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}
function CarouselButton(props: { onClick: () => void; side: "left" | "right" }) {
    const { hover, hoverProps } = useHover()
    return (
        <div
            {...hoverProps}
            style={{
                zIndex: 2,
                position: "absolute",
                top: 0,
                right: props.side === "right" ? 0 : undefined,
                left: props.side === "left" ? 0 : undefined,
                bottom: 0,
                width: 100,
                color: hover ? "white" : "transparent",
                textShadow: hover ? "0 0 3px black" : undefined,
                backgroundColor: hover ? "#ccc5" : undefined,
                transition: "all 0.20s ease",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                fontSize: 32,
            }}
            onClick={props.onClick}>
            <i className={`fas fa-chevron-${props.side}`} />
        </div>
    )
}

function CarouselCardView({ card }: { card: CarouselCard }) {
    switch (card.type) {
        case "Markdown":
            return (
                <div
                    style={{
                        backgroundColor: "#FAF6F1",
                        fontWeight: 400,
                        width: "100%",
                        height: "100%",
                        alignSelf: "center",
                        fontSize: 18,
                        fontFamily: "Montserrat",
                        lineHeight: 1.4,
                        borderRadius: 16,
                    }}>
                    <EditableText
                        obj={card}
                        isLocalized={true}
                        prop="body"
                        isMarkdown={true}
                        style={{ padding: 32 }}
                    />
                </div>
            )
        case "Image":
            return (
                <div
                    style={{
                        alignSelf: "center",
                        width: "100%",
                        maxWidth: 720,
                        display: "flex",
                        flexDirection: "column",
                        justifyItems: "center",
                        alignItems: "center",
                    }}>
                    <EditableImage
                        obj={card}
                        prop="image"
                        options={{ height: 516 }}
                        style={{
                            minHeight: 516,
                            width: "100%",
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            backgroundClip: "padding-box",
                        }}
                    />
                    <EditableText
                        obj={card}
                        prop="caption"
                        style={{ margin: 15, fontSize: 14, fontStyle: "italic" }}
                    />
                </div>
            )
        case "Video":
            return (
                <div
                    style={{
                        alignSelf: "center",
                        width: "100%",
                        maxWidth: 720,
                        display: "flex",
                        flexDirection: "column",
                        justifyItems: "center",
                        alignItems: "center",
                    }}>
                    <EditableVideo
                        obj={card}
                        prop="video"
                        posterProp="thumbnail"
                        posterOptions={{ height: 516 }}
                        style={{
                            height: 516,
                            width: "100%",
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            backgroundClip: "padding-box",
                        }}
                    />
                    <EditableText
                        obj={card}
                        prop="caption"
                        style={{ margin: 15, fontSize: 14, fontStyle: "italic" }}
                    />
                </div>
            )
    }
}
