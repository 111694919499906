import React, { useState } from "react"
import { Property } from "../../reactor/Types/Type"
import { Col, Form, Row } from "react-bootstrap"
import { ToolButton } from "./ToolButton"
import { PropRow } from "./PropRow"
import { RButton } from "./Buttons"
import { useDirtyContext } from "../../packages/editing/DirtyContext"
import { CodeEdit } from "./CodeEdit"
import { GetType } from "../../reactor"
import { ObjectPropView } from "./ObjectView"

export function JSONView({
    obj,
    buttons,
    label,
    property,
}: {
    property: Property
    obj: any
    buttons: ToolButton[]
    label?: string
}) {
    const [show, setShow] = useState(false)

    const typeProp = property.tags?.typeField
    if (typeProp) {
        const typeName = obj[typeProp]
        const type = GetType(typeName)

        return (
            <ObjectPropView
                obj={obj}
                property={{ name: property.name, type, tags: property.tags }}
                buttons={buttons}
                label={label}
                isEmbedded={true}
            />
        )
    }

    return (
        <PropRow buttons={buttons} label={label}>
            <Form.Group as={Row}>
                <Col>
                    <RButton variant="secondary" onClick={() => setShow(!show)}>
                        {show ? "Hide JSON" : "Show JSON"}
                    </RButton>

                    {property.description && (
                        <Form.Text className="text-muted">{property.description.en}</Form.Text>
                    )}
                </Col>
            </Form.Group>
            {show && (
                <Form.Group as={Row} style={{ height: 630 }}>
                    <Col>
                        <JsonViewInner obj={obj} property={property} />
                    </Col>
                </Form.Group>
            )}
        </PropRow>
    )
}

function JsonViewInner({ obj, property }: { obj: any; property: Property }) {
    const { setDirty } = useDirtyContext()

    return (
        <CodeEdit
            text={obj[property.name]}
            declarations={""}
            language="json"
            height={600}
            onEdited={(code) => {
                obj[property.name] = code
                setDirty()
            }}
        />
    )
}
