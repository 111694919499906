import Prando from "prando"
import { SendUnityMessage, SetUnityState } from "./UnityController"
import {
    BranchPoint,
    Character,
    DialogChoiceDto,
    DialogLine,
    DialogOption,
    GetSimulationDto,
    GetSimulationScenesDto,
    Narrative,
    Reflection,
    Run,
    RunAction,
    SayDto,
    StepDto,
} from "./client"
import { DateString } from "../../../reactor/Types/Primitives/DateTime"
import { Uuid } from "../../../reactor/Types/Primitives/Uuid"
import type { SavedGameData, StepRecord } from "../model/Run"
import { Clone } from "../../../reactor/Clone"

export type SimulationState = {
    /** The steps in the order they have been visited so far. */
    steps: StepDto[]

    /** The current scene */
    scene: GetSimulationScenesDto

    /** Provides the current game state.
     *
     *  Must be serialized immediately to retain a snapshot - the objects may change at any time.
     */
    saveGame(): SavedGameData

    /** Goes back to the/a previous step */
    goBackTo(to?: StepDto): void

    backgroundImage?: string
    say?: {
        dialogLine: DialogChoiceDto | SayDto
        avatar?: string
        name?: string
    }
    reflection?: { reflection: Reflection; continue: () => void }
    options?: { dialogOption: DialogOption; enabled: boolean; onClick: () => void }[]
    info?: { narrative: Narrative; continue: () => void }
    choices: Uuid<"DialogOption">[]
    variables: { [name: string]: string | number | boolean }
    evaluateCondition: (s: string) => boolean
    start: () => void

    /** Loads a new simulation into the ongoing playthrough.
     *
     *  It might work, it might not, but potentially a huge timesaver for things like tweaking
     *  animation/audio timings.
     */
    hotReload(sim: GetSimulationDto): void

    score?: number

    computeChoices: () => { red: number; green: number; yellow: number; neutral: number }
}

export type SimulationPlayerAPI = {
    /**  Indicates that the state has changed and UI should be updated. */
    invalidate: () => void

    /** This abstraction is required for working in both unity and non-unity mode.
     *
     *  In non-unity mode the audio is played through the browser, in unity mode it is played
     *  through unity.
     */
    speak: (
        characterId: Character["id"] | "Player",
        hash: string,
        emotion: string | undefined,
        callbacks: {
            started: () => void
            finished: () => void
        }
    ) => void

    precacheAudio(hashes: string[]): void

    /** Called when the user performs an action that probably should be recorded for analytics
     * purposes. */
    actionPerformed: (action: RunAction) => void

    /** Called when the user has received a score for this run that should be reported to backend
     * when online. */
    setScore: (score: number, success: boolean) => void

    /** Called when the user has completed the type profiler and has a set of
     * candiate personality types to chose from. */
    setPersonalityTypes: (types: string[]) => void

    /** Called when the end of the simulation is reached. */
    end(options?: { selectPersonalityType?: boolean }): void
}

export function Simulate(
    sim: GetSimulationDto,
    run: Run,
    /** Should be an integer between 0 and 99. Since we want to easily be able to reconstruct test
     *  cases, we want random seeds to be easy to remember, transfer orally and punch in for
     *  testing purposes. There is no need for a large number of random seeds.
     */
    randomSeed: number,
    api: SimulationPlayerAPI
): SimulationState {
    if ((randomSeed | 0) !== randomSeed) throw new Error("Random seed must be an integer")
    if (randomSeed < 0 || randomSeed > 99) throw new Error("Random seed must be between 0 and 99")

    function reconstructSteps(stepIds: Uuid<"StepBase">[], newSim = sim) {
        const newSteps = newSim.scenes.flatMap((s) => s.steps)

        return stepIds.map((s) => {
            const ss = newSteps.find((ns) => ns.id === s)
            if (!ss) {
                alert(
                    "Unable to load state because a step visited in this playthrough was removed. Simulation must be restarted."
                )
                throw new Error("Hot reload failed")
            }
            return ss
        })
    }
    function reconstructScene(sceneId: Uuid<"Scene">, newSim = sim) {
        const newScene = newSim.scenes.find((s) => s.id === state.scene.id)
        if (!newScene) {
            alert(
                "Unable to hot reload because the current scene was removed. Simulation must be restarted."
            )
            throw new Error("Hot reload failed")
        }
        return newScene
    }

    function saveGame(): SavedGameData {
        return {
            step: state.steps[state.steps.length - 1].id,
            choices: state.choices,
            variables: state.variables,
            scene: state.scene.id,
            simulationVersion: sim.version,
            stepRecord,
        }
    }

    function hotReload(newSim: GetSimulationDto) {
        sim = newSim
        state.steps = reconstructSteps(
            state.steps.map((x) => x.id),
            newSim
        )
        state.scene = reconstructScene(state.scene.id, newSim)

        newSim.variables.forEach((v) => {
            if (v.name in state.variables) {
                state.variables[v.name] = v.startingValue
            }
        })

        if (state.say) {
            const newLine = state.steps.find((s) => s.id === state.say!.dialogLine.id) as
                | SayDto
                | DialogChoiceDto
            if (!newLine) {
                alert(
                    "Unable to hot reload because the current dialog line was removed. Simulation must be restarted."
                )
                throw new Error("Hot reload failed")
            }

            const prompt = ("prompt" in newLine ? newLine.prompt : newLine) as
                | DialogLine
                | undefined

            state.say.dialogLine = newLine
            state.say.avatar = newSim.characters.find((c) => c.id === prompt?.character)?.portrait
            state.say.name = newSim.characters.find((c) => c.id === prompt?.character)?.name
        }

        const currentStep = getCurrentStep()
        if (state.options && "options" in currentStep) {
            setOptions(currentStep)
        }

        if (state.info) {
            state.info.narrative = state.steps.find(
                (s) => s.id === state.info!.narrative.id
            ) as Narrative
        }

        state.backgroundImage = getCurrentScene()?.stillImage

        ensureScenarioIsLoaded(api.invalidate)
    }

    // Generate three fixed random numbers available to the script logic, based on the random seed.
    const rnd = new Prando(randomSeed)
    const randomNumbers: number[] = []
    for (let i = 0; i < 100; i++) {
        randomNumbers.push(rnd.nextInt(0, 1000) / 1000)
    }

    let stepStart: DateString | undefined

    const steps = run.savedGame ? reconstructSteps([run.savedGame.step]) : [sim.scenes[0].steps[0]]
    if (steps.length === 0) throw new Error("No steps in the scene")

    const stepRecord: StepRecord = {
        step: steps[0].id,
        look: {},
        lookDirection: {},
        turn: {},
        poses: {},
        fov: undefined,
        fovDelay: undefined,
    }
    /** Keeps track of what the state was at certain steps, so that we can backtrack and get the
     * right states*/
    const stepRecords: StepRecord[] = []

    const state: SimulationState = {
        steps,
        saveGame,
        goBackTo(to) {
            setTimeout(() => {
                const i = Math.max(0, to ? state.steps.indexOf(to) : state.steps.length - 2)
                for (let n = state.steps.length - 1; n >= i; n--) {
                    if (state.steps[n].type === "Dialog choice") {
                        state.choices.pop()
                    }
                    stepRecords.pop()
                }
                if (i !== -1) {
                    state.steps.splice(i)
                }

                executeNextState(true)

                const targetStepRecord = stepRecords[stepRecords.length - 1]
                if (!targetStepRecord) throw new Error("Unable to find step record")
                restoreStepRecord(targetStepRecord)

                api.invalidate()
            }, 0)
        },
        choices: run.savedGame?.choices ?? [],
        scene:
            (run.savedGame?.scene && sim.scenes.find((sc) => sc.id === run.savedGame?.scene)) ??
            sim.scenes[0],
        variables:
            run.savedGame?.variables ??
            sim.variables
                .map((v) => ({ [v.name]: v.startingValue }))
                .reduce((a, b) => ({ ...a, ...b }), {}),
        evaluateCondition: evaluateScript,
        start: executeState,
        hotReload,
        computeChoices,
    }

    function restoreStepRecord({
        look,
        lookDirection,
        turn,
        imagePlanes,
        presentationVisible,
        presentationImage,
        poses,
        fov,
        fovDelay,
    }: StepRecord) {
        const characters = getCurrentScene()
            .characterConfig.map((c) => c.character)
            .filter((c) => !!c) as any as string[]

        // Restore actor states
        for (const actor in look) {
            if (!characters.includes(actor)) continue
            SetUnityState({
                actor,
                type: "look",
                target: [look[actor]],
            })
        }
        for (const actor in turn) {
            if (!characters.includes(actor)) continue
            SetUnityState({
                actor,
                type: "turn",
                target: [turn[actor]],
            })
        }
        if (lookDirection) {
            for (const actor in lookDirection) {
                SetUnityState({
                    actor,
                    type: "lookdirection",
                    target: lookDirection[actor],
                })
            }
        }

        if (imagePlanes !== undefined) {
            for (const actor in imagePlanes) {
                const p = imagePlanes[actor]
                SetUnityState({
                    actor,
                    type: "show_image",
                    target: [p.visible ? "true" : "false"],
                })
                if (p.image)
                    SetUnityState({
                        actor,
                        type: "change_image",
                        target: [p.image],
                    })
                if (p.width || p.height)
                    SetUnityState({
                        actor,
                        type: "change_size",
                        target: [p.width?.toString() ?? "1", p.height?.toString() ?? "1"],
                    })
            }
        }

        SetUnityState({
            actor: "Presentation",
            type: "show_image",
            target: [presentationVisible ? "true" : "false"],
        })
        if (presentationImage)
            SetUnityState({
                actor: "Presentation",
                type: "change_image",
                target: [presentationImage],
            })

        if (fov !== undefined) {
            SetUnityState({
                actor: "Player",
                type: "change_fov",
                // Expected encoding is the number, as a string literal
                target: fovDelay !== undefined ? [fov, fovDelay] : [fov],
            })
        }
        for (const actor in poses) {
            if (!characters.includes(actor)) continue
            SetUnityState({
                actor,
                type: "pose",
                target: [poses[actor]],
            })
        }
    }

    function getCurrentStep() {
        return state.steps[state.steps.length - 1]
    }

    function getCurrentScene() {
        const s = sim.scenes.find((x) => x.steps.some((y) => y === getCurrentStep()))
        if (!s) throw new Error("No current scene")
        return s
    }

    state.backgroundImage = getCurrentScene()?.stillImage

    function gotoNextStep(hasBacktracked: boolean) {
        if (state.steps.length === 0) {
            state.steps = [sim.scenes[0].steps[0]]
            return
        }

        const scene = getCurrentScene()
        const step = getCurrentStep()

        if (scene && step) {
            if (hasBacktracked && step.type === "Dialog choice") {
                // This happens if we backtracked to a choice we have already made
                const choice = state.choices[state.choices.length - 1]
                const option = step.options.find((x) => x.uuid === choice)
                if (option) {
                    if (option.effect) {
                        evaluateScript(option.effect, option)
                    } else {
                        gotoNextStep(false)
                    }
                    return
                }
            }

            if ("nextStep" in step && step.nextStep) {
                const next = findStep(step.nextStep)
                if (next) state.steps.push(next)
                else console.log("Step not found: " + step.nextStep)
            } else {
                const stepIndex = scene.steps.indexOf(step)
                if (stepIndex === scene.steps.length - 1) {
                    const sceneIndex = sim.scenes.indexOf(scene)
                    const nextScene = sim.scenes[sceneIndex + 1]
                    state.steps.push(nextScene.steps[0])
                    state.backgroundImage = nextScene.stillImage
                } else {
                    state.steps.push(scene.steps[stepIndex + 1])
                }
            }
        }

        stepStart = DateString()
    }
    function findStep(id: StepDto["id"]) {
        return sim.scenes.flatMap((scene) => scene.steps).find((step) => step.id === id)
    }
    function selectOption(option: DialogOption) {
        const step = getCurrentStep()

        if (option.uuid) state.choices.push(option.uuid)

        if (option.effect) {
            evaluateScript(option.effect, option)
        }

        if (step === getCurrentStep()) {
            // Script had no effect, let's do default branching

            if (option.targets.length) {
                const target = findStep(option.targets[0])
                if (target) state.steps.push(target)
                else console.log("Step not found: " + option.targets[0])
            } else {
                gotoNextStep(false)
            }
        }

        if (stepStart && step) {
            api.actionPerformed({
                // Use the ID from the step we started on. `getCurrentStep()`
                // may have changed because of the side effect of
                // `evaluateScript()`
                step: step.id,
                choice: option.id,
                choiceUuid: option.uuid,
                color: option.color,
                start: stepStart,
                end: DateString(),
                scene: getCurrentScene().id,
            })
        }
    }
    function jumpTo(targetIndex: number, branchPoint?: BranchPoint) {
        if (!branchPoint) {
            throw new Error("Cannot jumpTo() - not at a branchpoint")
        }
        const stepId = branchPoint.targets[targetIndex]
        if (!stepId) {
            throw new Error("Cannot jumpTo() - invalid target index: " + targetIndex)
        }
        const step = findStep(stepId)
        if (!step) {
            throw new Error("Cannot jumpTo() - invalid step reference: " + stepId)
        }
        state.steps.push(step)
    }
    function computeChoices() {
        const allOptions = (
            sim.scenes
                .flatMap((s) => s.steps)
                .filter((s) => s.type === "Dialog choice") as DialogChoiceDto[]
        ).flatMap((dc) => dc.options)
        const chosenOptions = state.choices.map((id) => allOptions.find((o) => o.uuid === id))
        return {
            red: chosenOptions.filter((x) => x?.color === "Red").length,
            yellow: chosenOptions.filter((x) => x?.color === "Yellow").length,
            green: chosenOptions.filter((x) => x?.color === "Green").length,
            neutral: chosenOptions.filter((x) => x?.color === "Neutral").length,
        }
    }

    function evaluateScript(s: string, branchPoint?: BranchPoint, onJump?: () => void) {
        const code = `(function(state, jumpTo, random, choices, setScore, setPersonalityTypes, numberOfPlaythroughs, highscore) {
                ${sim.variables.map((v) => `let ${v.name} = state.${v.name};`).join("\n")}
                ${s}
                ${sim.variables.map((v) => `state.${v.name} = ${v.name};`).join("\n")}
            })`

        // eslint-disable-next-line no-eval
        return eval(code)(
            state.variables,
            /** jumpTo */
            (index: number) => {
                if (onJump) onJump()
                jumpTo(index, branchPoint)
            },
            randomNumbers,
            computeChoices(),
            /** setScore */
            (score: number, success: boolean) => {
                state.score = score
                api.setScore(score, success)
            },
            /** setPersonalityTypes */
            (types: string[]) => {
                api.setPersonalityTypes(types)
            },
            /** numberOfPlaythroughs */
            sim.numberOfPlaythroughs,
            /** highscore */
            sim.highscore
        )
    }

    function executeNextState(hasBacktracked = false) {
        gotoNextStep(hasBacktracked)
        executeState()
    }

    function setOptions(dc: DialogChoiceDto) {
        state.options = dc.options.map((dialogOption) => ({
            enabled: dialogOption.condition
                ? evaluateScript(dialogOption.condition, dialogOption)
                : true,
            dialogOption,
            onClick() {
                state.options = undefined
                api.invalidate()
                selectOption(dialogOption)
                executeState()
            },
        }))
        api.invalidate()
    }

    let loadedScenarioJson: string | null = null

    /** Ensures the curren scenario is loaded, and calls the callback when done */
    function ensureScenarioIsLoaded(callback: () => void) {
        const scene = sim.scenes.find((sc) => sc.id === state.scene.id)

        const hashes: string[] = []
        scene?.steps.forEach((s) => {
            if (s.type === "Say") hashes.push(s.hash)
            if (s.type === "Dialog choice" && s.prompt) hashes.push(s.hash)
        })
        if (hashes.length) api.precacheAudio(hashes)

        const loadingSavedGame = run.savedGame && loadedScenarioJson === null

        if (state.scene.env === undefined) {
            if (!state.scene.stillImage) {
                throw new Error("Must specify either environment or still image")
            }
            loadedScenarioJson = `SPLASH: ${state.scene.stillImage.valueOf()}`

            SetUnityState({
                actor: "SplashScreen",
                type: "show_splash",
                target: [state.scene.stillImage],
            })
            callback()
            return
        }

        const showingSplash = loadedScenarioJson?.startsWith("SPLASH: ")

        const env = sim.environments.find((e) => e.id === state.scene.env)
        const scenarioDesc = {
            environmentName: env?.unityName ?? "Ship_Office01",
            humans: state.scene.characterConfig
                ?.filter((x) => x.character)
                .map((c) => {
                    const chr = sim.characters.find((x) => x.id === c.character)
                    if (!chr) throw new Error()

                    return {
                        name: chr.id.valueOf() ?? "Player",
                        headID: chr.head,
                        bodyID: chr.body,
                        eyeAccID: c.eyeAccessory,
                        headAccID: c.headAccessory,
                    }
                }),
            initialStates: state.scene.characterConfig.flatMap((c) => {
                const actor = c.character ?? "Player"
                const states = [
                    {
                        actor,
                        type: "toggle_autolook",
                        target: [c.autoLook ? "true" : "false"],
                    },
                    {
                        actor: c.character?.valueOf() ?? "Player",
                        type: c.action,
                        target: [c.actionTarget],
                    },
                ]

                const lookAt = c.lookAtCharacter
                    ? ([c.lookAtCharacter] as Uuid<"Character">[])
                    : c.lookAtSpecial
                if (lookAt) {
                    states.push({
                        actor,
                        type:
                            typeof lookAt === "object" && lookAt instanceof Array
                                ? "look_group"
                                : "look",
                        target: [encodeLookAtGroup(lookAt)],
                    })
                    if (!loadingSavedGame) stepRecord.look[actor.valueOf()] = lookAt
                }

                const turnTowards = c.turnTowardsCharacter ?? c.turnTowardsSpecial ?? lookAt
                if (
                    turnTowards &&
                    !(typeof turnTowards === "object" && turnTowards instanceof Array)
                ) {
                    states.push({
                        actor,
                        type: "turn",
                        target: [turnTowards.valueOf()],
                    })
                    if (!loadingSavedGame) stepRecord.turn[actor.valueOf()] = turnTowards
                }

                return states
            }),
        }

        for (const p of state.scene.imagePlaneConfig ?? []) {
            scenarioDesc.initialStates.push({
                actor: p.actor,
                type: "show_image",
                target: [p.visible ? "true" : "false"],
            })
            if (p.image)
                scenarioDesc.initialStates.push({
                    actor: p.actor,
                    type: "change_image",
                    target: [p.image],
                })
            if (p.width || p.height)
                scenarioDesc.initialStates.push({
                    actor: p.actor,
                    type: "change_size",
                    target: [p.width?.toString() ?? "1", p.height?.toString() ?? "1"],
                })

            if (!loadingSavedGame) {
                stepRecord.imagePlanes ??= {}
                stepRecord.imagePlanes[p.actor] ??= {}
                stepRecord.imagePlanes[p.actor].visible = p.visible
                stepRecord.imagePlanes[p.actor].image = p.image
                stepRecord.imagePlanes[p.actor].width = p.width
                stepRecord.imagePlanes[p.actor].height = p.height
            }
        }

        scenarioDesc.initialStates.push({
            actor: "Presentation",
            type: "show_image",
            target: [state.scene.presentationVisible ? "true" : "false"],
        })
        if (state.scene.presentationImage)
            scenarioDesc.initialStates.push({
                actor: "Presentation",
                type: "change_image",
                target: [state.scene.presentationImage],
            })
        if (state.scene.fov)
            scenarioDesc.initialStates.push({
                actor: "Player",
                type: "change_fov",
                // Expected encoding is the number, as a string literal
                target: [state.scene.fov.toString()],
            })
        if (state.scene.lookTarget)
            scenarioDesc.initialStates.push({
                actor: state.scene.lookActor ?? "Player",
                type:
                    typeof state.scene.lookTarget === "object" &&
                    state.scene.lookTarget instanceof Array
                        ? "look_group"
                        : "look",
                target: [encodeLookAtGroup(state.scene.lookTarget)],
            })
        if (state.scene.backgroundImage)
            scenarioDesc.initialStates.push({
                actor: "Sky",
                type: "set_2d",
                target: [state.scene.backgroundImage],
            })

        if (!loadingSavedGame) {
            stepRecord.presentationVisible = state.scene.presentationVisible
        }

        const scenarioJson = JSON.stringify(scenarioDesc)

        function hideSplash() {
            SetUnityState({
                actor: "SplashScreen",
                type: "hide_splash",
                target: [],
            })
        }

        if (
            // Detects live changes to the scenario to allow for live editing
            loadedScenarioJson !== scenarioJson
        ) {
            loadedScenarioJson = scenarioJson

            SendUnityMessage("NewScenario", scenarioDesc, ({ state: cbstate }) => {
                if (cbstate === "Finished") {
                    if (showingSplash) hideSplash()

                    if (loadingSavedGame && run.savedGame)
                        restoreStepRecord(run.savedGame.stepRecord)

                    callback()
                }
            })
        } else {
            if (showingSplash) hideSplash()
            callback()
            if (loadingSavedGame && run.savedGame) restoreStepRecord(run.savedGame.stepRecord)
        }
    }

    function executeState() {
        // Remember the step record at this step, for backtracking
        stepRecords.push(Clone(stepRecord))

        state.scene = getCurrentScene()
        state.options = undefined
        state.info = undefined
        state.say = undefined
        state.backgroundImage = getCurrentScene()?.stillImage

        const step = getCurrentStep()

        ensureScenarioIsLoaded(() => {
            console.log("STEP", step)
            switch (step.type) {
                case "Say":
                    say(step.character ?? "Player", step.hash, step, executeNextState)
                    break
                case "Dialog choice":
                    {
                        const dc = step
                        if (step.prompt)
                            say(step.prompt.character ?? "Player", step.hash, step, () =>
                                setOptions(dc)
                            )
                        else setOptions(dc)
                    }
                    break
                case "Script":
                    {
                        let didJump = false
                        evaluateScript(step.script, step, () => (didJump = true))
                        if (didJump) executeState()
                        else executeNextState()
                    }
                    break
                case "Narrative":
                    state.say = undefined
                    state.options = undefined
                    state.info = {
                        narrative: step,
                        continue: () => {
                            state.info = undefined
                            api.invalidate()
                            executeNextState()
                        },
                    }
                    break
                case "ImagePlane":
                case "Presentation":
                case "Look":
                case "LookAway":
                case "LookDirection":
                case "ChangeFov":
                case "SetPose":
                    executeActionStep(step)
                    executeNextState()
                    break
                case "Wait":
                    setTimeout(executeNextState, step.milliseconds)
                    break
                case "End":
                    state.say = undefined
                    state.options = undefined
                    state.info = undefined
                    api.end({ selectPersonalityType: step.selectPersonalityType })
                    break
                case "Reflection":
                    state.reflection = {
                        reflection: step,
                        continue: () => {
                            state.reflection = undefined
                            api.invalidate()
                            executeNextState()
                        },
                    }
                    break

                default:
                    console.error("Unknown simulation step type: " + (step as any).type)
            }
            api.invalidate()
        })
    }
    function executeActionStep(step: StepDto) {
        switch (step.type) {
            case "ImagePlane":
                if (step.action === "Show") {
                    SetUnityState({
                        actor: step.actor,
                        type: "show_image",
                        target: ["true"],
                    })
                    stepRecord.imagePlanes ??= {}
                    stepRecord.imagePlanes[step.actor] ??= {}
                    stepRecord.imagePlanes[step.actor].visible = true
                }
                if (step.action === "Hide") {
                    SetUnityState({
                        actor: step.actor,
                        type: "show_image",
                        target: ["false"],
                    })
                    stepRecord.imagePlanes ??= {}
                    stepRecord.imagePlanes[step.actor] ??= {}
                    stepRecord.imagePlanes[step.actor].visible = false
                }
                if (step.image) {
                    SetUnityState({
                        actor: step.actor,
                        type: "change_image",
                        target: [step.image],
                    })
                    stepRecord.imagePlanes ??= {}
                    stepRecord.imagePlanes[step.actor] ??= {}
                    stepRecord.imagePlanes[step.actor].image = step.image
                }
                if (step.width || step.height) {
                    SetUnityState({
                        actor: step.actor,
                        type: "change_size",
                        target: [step.width?.toString() ?? "1", step.height?.toString() ?? "1"],
                    })
                    stepRecord.imagePlanes ??= {}
                    stepRecord.imagePlanes[step.actor] ??= {}
                    stepRecord.imagePlanes[step.actor].width = step.width
                    stepRecord.imagePlanes[step.actor].height = step.height
                }
                break
            case "Presentation":
                if (step.action === "Show") {
                    SetUnityState({
                        actor: "Presentation",
                        type: "show_image",
                        target: ["true"],
                    })
                    stepRecord.presentationVisible = true
                }
                if (step.action === "Hide") {
                    SetUnityState({
                        actor: "Presentation",
                        type: "show_image",
                        target: ["false"],
                    })
                    stepRecord.presentationVisible = false
                }
                if (step.image) {
                    SetUnityState({
                        actor: "Presentation",
                        type: "change_image",
                        target: [step.image],
                    })
                    stepRecord.presentationImage = step.image
                }
                break
            case "Look":
                {
                    const actor = step.actor ?? "Player"
                    const target = step.specialTarget ?? step.target ?? "Player"
                    if (target) {
                        const encodedTarget = encodeLookAtGroup(target)
                        SetUnityState({
                            actor,
                            type:
                                typeof target === "object" && target instanceof Array
                                    ? "look_group"
                                    : "look",
                            target: [encodedTarget],
                        })
                        stepRecord.look[actor.valueOf()] = target
                        if (step.turnBody) {
                            SetUnityState({
                                actor,
                                type: "turn",
                                target: [target],
                            })
                            stepRecord.turn[actor.valueOf()] = target
                        }
                    }
                }
                break
            case "LookAway":
                {
                    const actor = step.actor ?? "Player"
                    SetUnityState({
                        actor,
                        type: "lookaway",
                        target: [],
                    })
                }
                break
            case "LookDirection":
                {
                    const actor = step.actor ?? "Player"
                    const target: string[] = [step.turn.toString()]
                    if (step.pitch) {
                        target.push(step.pitch.toString())
                        if (step.distance) {
                            target.push(step.distance.toString())
                        }
                    }

                    SetUnityState({
                        actor,
                        type: "lookdirection",
                        target,
                    })

                    stepRecord.lookDirection ??= {} // older save games don't have this
                    stepRecord.lookDirection[actor.valueOf()] = target
                }
                break
            case "ChangeFov":
                SetUnityState({
                    actor: "Player",
                    type: "change_fov",
                    // Expected encoding is the number, as a string literal
                    target:
                        step.delay !== undefined
                            ? [step.degrees.toString(), step.delay.toString()]
                            : [step.degrees.toString()],
                })
                stepRecord.fov = step.degrees
                stepRecord.fovDelay = step.delay
                break
            case "SetPose":
                {
                    const actor = step.actor.valueOf() ?? "Player"
                    const pose = sim.poses.find((p) => p.id === step.pose)?.unityName
                    SetUnityState({
                        actor,
                        type: "pose",
                        target: [pose],
                    })
                    if (pose) stepRecord.poses[actor] = pose
                }
                break
        }
    }
    function say(
        characterId: Character["id"] | "Player",
        hash: string,
        dialogLine: DialogChoiceDto | SayDto,
        whenDone: () => void
    ) {
        const prompt = ("prompt" in dialogLine ? dialogLine.prompt : dialogLine) as
            | DialogLine
            | undefined

        const animations =
            "animations" in dialogLine
                ? dialogLine.animations
                : "prompt" in dialogLine
                  ? dialogLine.prompt?.animations
                  : undefined

        const speaker = sim.characters.find((c) => c.id === prompt?.character)

        state.say = {
            dialogLine,
            avatar: speaker?.portrait,
            name: speaker?.name,
        }

        if (prompt?.text) {
            api.speak(
                characterId,
                hash,
                prompt.showEmotion
                    ? sim.emotions.find((e) => e.name === prompt.emotion)?.unityName
                    : undefined,
                {
                    started() {
                        animations?.forEach((anim) => {
                            setTimeout(() => {
                                const character = anim.character ?? characterId
                                if (character !== "Player") {
                                    SendUnityMessage("SetState", {
                                        actor: character,
                                        type: anim.name,
                                        target: anim.target ? [anim.target] : [],
                                    })
                                }
                            }, anim.delay)
                        })
                    },
                    finished: whenDone,
                }
            )
        }
    }

    return state
}

function encodeLookAtGroup(lookAt: Uuid<"Character">[] | "Player" | "Presentation" | undefined) {
    if (lookAt === undefined || lookAt === "Player") return "Player"
    if (lookAt === "Presentation") return "Presentation"
    return `[${lookAt.map((c) => c.toString()).join(", ")}]`
}
