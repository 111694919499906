import React from "react"
import { useState } from "react"
import { Reorder } from "./Reorder"
import {
    postQuizResponse,
    PostQuizResponseDto,
    QuizImage,
    QuizQuestion,
    QuizQuestionResponse,
    QuizStep,
    QuizString,
    useQuiz,
} from "./client"
import { Uuid } from "../../../reactor/Types/Primitives"
import { correctGreen, darkMustard, incorrectRed, yellowBG } from "./colors"
import { ExitButton } from "./ExitButton"
import { ScoreScreen } from "./ScoreScreen"
import { getQuizResponseScore } from "./QuizScoring"
import ReactDOM from "react-dom"
import { SubmitButton } from "./SubmitButton"
import { ProgressBar } from "./ProgressBar"
import { useLocalize } from "../../../packages/localization/client-side/useLocalize"
import { MarkdownView } from "../../../packages/markdown-edit/MarkdownView"

export function QuizView(props: { id: Uuid<"Quiz">; exit: () => void }) {
    const localize = useLocalize()
    const { data: quiz, error } = useQuiz(props.id)

    const [submitButtonPortal, setSubmitButtonPortal] = useState<HTMLDivElement | null>(null)

    const [progress, setProgress] = useState(0)
    const [responses] = useState([] as QuizQuestionResponse[])
    const [scoreScreen, setScoreScreen] = useState<PostQuizResponseDto | null>(null)

    function content() {
        if (!step || !quiz || !submitButtonPortal) return <></>
        switch (step.type) {
            case "Info":
                return <div>{localize(step.content)}</div>
            case "Question":
                return (
                    <QuestionView
                        submitButtonPortal={submitButtonPortal}
                        key={progress} // Makes sure the state is reset when re-visiting earlier questions
                        step={step}
                        advance={async (response: QuizQuestionResponse) => {
                            responses.push(response)
                            setProgress(progress + 1)
                            if (progress + 1 >= quiz.steps.length) {
                                setScoreScreen(
                                    await postQuizResponse(props.id, {
                                        questions: responses,
                                        /** Need to calculate this on the client to get scoring on
                                         * the exact version of the quiz we played. */
                                        ...getQuizResponseScore(quiz, responses),
                                    })
                                )
                            }
                        }}
                    />
                )
            case "Simulation":
                return (
                    <div>
                        <div>Simulations not supported yet</div>
                        <button onClick={() => setProgress(progress + 1)}>Next</button>
                    </div>
                )
        }
    }

    if (error) return <>Error: {JSON.stringify(error)}</>
    if (!quiz) return <>No quiz: {props.id}</>

    const step: QuizStep | undefined = quiz.steps[progress]
    const percent = (progress / quiz.steps.length) * 100

    return (
        <div
            style={{
                width: "100%",
                height: "100%",
                backgroundColor: yellowBG,
                position: "relative",
                overflow: "hidden",
            }}>
            <ExitButton onClick={props.exit} />
            <div
                style={{
                    overflowY: "auto",
                    width: "100%",
                    height: "100%",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                    paddingTop: 96,
                }}>
                {step && (
                    <>
                        <div style={{ maxWidth: 800, width: "100%" }}>
                            <div key={step.id.valueOf()}>{content()}</div>
                        </div>
                        <ProgressBar
                            percent={percent}
                            text={`${progress}/${quiz.steps.length} Complete`}>
                            <div ref={(r) => setSubmitButtonPortal(r)}>
                                <div style={{ height: 0, width: 0 }} />
                            </div>
                        </ProgressBar>
                    </>
                )}
                {scoreScreen && (
                    <ScoreScreen
                        exit={props.exit}
                        score={scoreScreen.score}
                        scoreMax={scoreScreen.maxScore}
                        infoText={quiz.scoreInfo}
                        success={true}
                    />
                )}
            </div>
        </div>
    )
}

function QuestionView({
    step,
    submitButtonPortal,
    advance,
}: {
    step: QuizQuestion
    submitButtonPortal: HTMLDivElement
    advance: (response: QuizQuestionResponse) => void
}) {
    const localize = useLocalize()
    const options = step.options.type === "Images" ? step.options.images : step.options.strings

    const [answers, setAnswers] = useState(
        step.isArrangeChallenge ? randomizeOrder(options.map((o) => o.id)) : []
    )
    const [submitted, setSubmitted] = useState(false)

    const correctCount = options.map((o) => (o.correct ? 1 : 0)).reduce((a: number, b) => a + b, 0)

    const success: boolean | undefined = submitted
        ? step.isArrangeChallenge
            ? answers.every((a, i) => a === options[i].id)
            : answers.every((a) => options.some((x) => x.id === a && x.correct)) &&
              options
                  .map((o) => !o.correct || answers.includes(o.id))
                  .reduce((a, b) => a && b, true)
        : undefined

    const optionsOrdered: (QuizImage | QuizString)[] = step.isArrangeChallenge
        ? answers.map((a) => (options as any).find((o: QuizImage | QuizString) => o.id === a))
        : options

    const OptionButton = (s: QuizString | QuizImage): React.ReactNode => {
        const selected = !step.isArrangeChallenge && answers.includes(s.id)

        function getBorderColor() {
            if (success === undefined) {
                if (selected) return darkMustard
                else return "transparent"
            } else {
                if (s.correct) return correctGreen
                if (selected && !s.correct) return incorrectRed
            }
            return "transparent"
        }
        function getBackgroundColor() {
            if (success === undefined) {
                return "white"
            } else {
                if (s.correct && selected) return correctGreen
            }
            return "white"
        }
        function getForegroundColor() {
            if (success !== undefined) {
                if (s.correct && selected) return "white"
            }
            return "black"
        }

        const color =
            success === undefined
                ? selected
                    ? darkMustard
                    : undefined
                : s.correct && selected
                  ? correctGreen
                  : !s.correct && selected
                    ? incorrectRed
                    : s.correct && !selected
                      ? correctGreen
                      : undefined

        return (
            <div
                onClick={
                    step.isArrangeChallenge
                        ? undefined
                        : () => {
                              if (success !== undefined) return
                              if (correctCount === 1) {
                                  answers.splice(0, answers.length)
                                  answers.push(s.id)
                              } else {
                                  if (!selected) {
                                      if (answers.length >= correctCount) {
                                          alert(
                                              `You can only select ${correctCount} options on this quiz question.`
                                          )
                                          return
                                      }
                                      answers.push(s.id)
                                  } else {
                                      answers.splice(answers.indexOf(s.id), 1)
                                  }
                              }
                              setAnswers([...answers])
                          }
                }
                style={{
                    ...("image" in s
                        ? {
                              backgroundImage: `url(${localize(s.image)})`,
                              height: 200,
                              width: 200,
                              backgroundPosition: "center",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "cover",
                          }
                        : {
                              padding: 16,
                          }),

                    marginTop: step.isArrangeChallenge ? undefined : 16,
                    cursor: step.isArrangeChallenge ? "grab" : "hand",
                    boxShadow: "rgba(0, 0, 0, 0.15) 0px 2.5px 7.5px",
                    borderWidth: 3,
                    borderStyle: "solid",
                    borderColor: getBorderColor(),
                    borderRadius: 16,
                    transition: "all 0.2s ease-in-out",
                    backgroundColor: getBackgroundColor(),
                    color: getForegroundColor(),
                }}>
                {"text" in s ? (
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        {!step.isArrangeChallenge && (
                            <div
                                style={{
                                    borderWidth: 2,
                                    borderStyle: "solid",
                                    borderColor: selected ? color : "#ccc",
                                    borderRadius: 8,
                                    width: 32,
                                    height: 32,
                                    marginRight: 16,
                                    backgroundColor: selected ? color : "white",
                                    transition: "all 0.2s ease-in-out",
                                }}>
                                {selected && (
                                    <i
                                        className={`fas fa-check`}
                                        style={{ fontSize: 22, margin: 4, color: "white" }}
                                    />
                                )}
                            </div>
                        )}
                        <div style={{ flex: 1 }}>{localize(s.text)}</div>
                    </div>
                ) : (
                    <div
                        style={{
                            // A linear gradient that only paints the lower-right corner yellow
                            background: selected
                                ? `linear-gradient(to bottom right, transparent 80%, ${color} 80.5%, ${color} 100%)`
                                : undefined,
                            border:
                                success === undefined
                                    ? `1px solid ${selected ? color : "transparent"}`
                                    : undefined,
                            width: "100%",
                            height: "100%",
                            borderRadius: 10,
                        }}
                    />
                )}
            </div>
        )
    }
    const optionButtons = optionsOrdered.map(OptionButton)

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ fontSize: 24, fontWeight: "bold", textAlign: "center" }}>
                <MarkdownView value={localize(step.question)} />
            </div>
            {step.image && (
                <img
                    src={step.image}
                    style={{
                        alignSelf: "center",
                        maxWidth: 400,
                        maxHeight: 225,
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: 16,
                        marginTop: 32,
                    }}
                />
            )}
            <div
                style={{
                    color:
                        success === undefined ? darkMustard : success ? correctGreen : incorrectRed,
                    fontWeight: "bold",
                    marginTop: 32,
                }}>
                {success === undefined
                    ? step.instruction
                        ? localize(step.instruction)
                        : step.isArrangeChallenge
                          ? "Drag-and-drop to arrange the answers"
                          : correctCount === 1
                            ? "Select the correct answer"
                            : "Select all applicable answers"
                    : success === true
                      ? "Correct!"
                      : answers.every((a) => options.some((x) => x.id === a && x.correct))
                        ? answers.length > correctCount
                            ? "Incorrect - too many options selected"
                            : answers.length < correctCount
                              ? "Incorrect - too few options selected"
                              : "Incorrect"
                        : "Incorrect"}
            </div>
            {step.isArrangeChallenge ? (
                <div
                    style={{
                        display: "flex",
                        flexDirection: step.options.type === "Images" ? "row" : "column",
                        flexWrap: step.options.type === "Images" ? "wrap" : "nowrap",
                        width: "100%",
                    }}>
                    <Reorder<QuizString | QuizImage>
                        key={step.id.valueOf()}
                        present={OptionButton}
                        items={optionsOrdered}
                        makeKey={(o) => o.id.valueOf()}
                        reorderStyle="padding-top: 16px; "
                        reorder={(srcIndex, dstIndex) => {
                            answers.splice(dstIndex, 0, answers.splice(srcIndex, 1)[0])
                            setAnswers([...answers])
                        }}
                    />
                </div>
            ) : (
                <div
                    style={{
                        display: "flex",
                        flexDirection: step.options.type === "Images" ? "row" : "column",
                        flexWrap: step.options.type === "Images" ? "wrap" : "nowrap",
                        width: "100%",
                    }}>
                    {optionButtons}
                </div>
            )}
            {ReactDOM.createPortal(
                <SubmitButton
                    text={submitted ? "NEXT" : "SUBMIT"}
                    style={{
                        marginLeft: 32,
                        backgroundColor:
                            success === undefined
                                ? darkMustard
                                : success
                                  ? correctGreen
                                  : incorrectRed,
                        opacity: answers.length > 0 ? 1 : 0.6,
                    }}
                    onClick={() => {
                        if (submitted) {
                            advance({ question: step.id, response: answers })
                        } else if (answers.length > 0) {
                            setSubmitted(true)
                        }
                    }}
                />,
                submitButtonPortal
            )}
        </div>
    )
}

function randomizeOrder<T>(arr: T[]): T[] {
    const result = [...arr]
    for (let i = 0; i < result.length; i++) {
        const j = Math.floor(Math.random() * result.length)
        const temp = result[i]
        result[i] = result[j]
        result[j] = temp
    }
    return result
}
