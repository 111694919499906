import React, { useEffect } from "react"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import { useHover } from "../../../packages/hooks/useHover"

export function Reorder<T>({
    key,
    items,
    makeKey,
    reorderStyle,
    reorder,
    present,
}: {
    key: string
    items: T[]
    present: (item: T) => React.ReactNode
    makeKey: (item: T) => string
    reorder: (src: number, dst: number) => void
    reorderStyle: string
}) {
    const { hover, hoverProps } = useHover()

    // Hack to get around margin calculation issues in react-beautiful-dnd
    useEffect(() => {
        if (!reorderStyle) return () => {}
        const styleSheet = document.createElement("style")
        styleSheet.innerText = `.reorderStyle-${key} { ${reorderStyle} }`
        document.head.appendChild(styleSheet)
        return () => document.head.removeChild(styleSheet)
    }, [reorderStyle])

    return (
        <DragDropContext
            onDragEnd={async (result) => {
                if (!result.destination) {
                    return
                }
                const src = result.source.index
                const dst = result.destination.index
                reorder(src, dst)
            }}>
            <Droppable droppableId="droppable">
                {(providedDrop, snapDrop) => (
                    <div {...providedDrop.droppableProps} ref={providedDrop.innerRef}>
                        {items.map((item, index) => {
                            const k = makeKey(item)

                            return (
                                <Draggable key={k} draggableId={k} index={index}>
                                    {(providedDrag: any, snapDrag) => {
                                        return (
                                            <div
                                                ref={providedDrag.innerRef}
                                                className={
                                                    reorderStyle ? `reorderStyle-${key}` : undefined
                                                }
                                                {...providedDrag.draggableProps}
                                                {...providedDrag.dragHandleProps}>
                                                {present(item)}
                                            </div>
                                        )
                                    }}
                                </Draggable>
                            )
                        })}
                        {providedDrop.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    )
}
