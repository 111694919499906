import React from "react"
import { ColorStyles } from "../../packages/ui"

/** Visualizes a missing value or object. */
export function None() {
    return (
        <div
            style={{
                border: `1px solid ${ColorStyles.gray[200]}`,
                borderRadius: 8,
                padding: 8,
                paddingLeft: 12,
                fontStyle: "italic",
                color: ColorStyles.gray[400],
            }}>
            None
        </div>
    )
}
