import React, { CSSProperties, useState } from "react"
import {
    GetModulesModulesDto,
    GetModulesUserDto,
    GetSimulationDto,
    ModuleFacilitatorStepDto,
    ModuleStepDto,
    postRun,
    postRunSession,
    GetQuizDto,
    Run,
    Survey,
    useModules,
    useSession,
    useActionCards,
    ActionCard,
    GetSessionDto,
    GetModulesCustomerDto,
} from "./client"
import { darkMustard, yellowBG } from "./colors"
import { MenuBar } from "./MenuBar"
import { ModuleCard } from "./ModuleCard"
import { ModuleStepBox, StepCardButton } from "./ModuleStepCard"
import { Missing } from "./Missing"
import { SimulationPlayerLoader } from "./SimulationPlayer"
import { QuizView } from "./QuizView"
import { IsNode } from "../../../reactor/AssertNode"
import { Uuid } from "../../../reactor/Types/Primitives/Uuid"
import { SurveyView } from "./SurveyView"
import { Modal } from "../../../packages/modal/Modal"
import { Outlet, useOutletContext, useParams } from "react-router-dom"
import { ModuleStepDtoBase, ModuleStepVideoDto } from "../api"
import { SessionToolbar } from "./SessionToolbar"
import { PartialCircle } from "./PartialCircle"
import { useLocalSession } from "./useLocalSession"
import { useNavigate } from "../../../packages/hooks/useNavigate"
import { ellipsize } from "../../../reactor/Helpers"
import { SelectPersonalityTypeView } from "./SelectPersonalityTypeView"
import { FacilitatorStepBox } from "./FacilitatorStepCard"
import { Localized } from "../../../packages/localization/Localized"
import { ActionCardTipModal } from "./Dashboard"
import { useLocalize } from "../../../packages/localization/client-side/useLocalize"
import { Markdown } from "../../../reactor/Types/Primitives/Markdown"
import { MarkdownView } from "../../../packages/markdown-edit/MarkdownView"
import { VideoView } from "./VideoView"

export type HomeContext = {
    refresh: () => Promise<void>
    modules: GetModulesModulesDto[]
    customer: GetModulesCustomerDto
    user: GetModulesUserDto
    disableDashboard: undefined | boolean
    disableLeaderboards: undefined | boolean
    ongoingModule: undefined | Uuid<"Module">
    simulationsInFocus: undefined | Uuid<"Simulation">[]
    surveysInFocus: undefined | Uuid<"Survey">[]
    articlesInFocus: undefined | Uuid<"Article">[]
    launchSimulation: (id: Uuid<"Simulation">, continueFromSave: boolean) => void
    launchSurvey: (id: Uuid<"Survey">) => void
    launchQuiz: (id: Uuid<"Quiz">) => void
    launchVideo: (step: ModuleStepVideoDto) => void
}

export function Home() {
    // usePreference is needed to render this page
    if (IsNode()) return <></>

    const modules = useModules()
    const [opacity, setOpacity] = useState(1)
    const [simId, setSimId] = useState<GetSimulationDto["id"] | null>(null)
    const [run, setRun] = useState<Run | null>(null)
    const [quizId, setQuizId] = useState<GetQuizDto["id"] | null>(null)
    const [surveyId, setSurveyId] = useState<Survey["id"] | null>(null)
    const [selectPersonalityType, setSelectPersonalityType] = useState<string[] | undefined>(
        undefined
    )
    const [videoStep, setVideoStep] = useState<ModuleStepVideoDto | null>(null)

    if (!modules.data) return <Missing {...modules} />

    function fadeOut(callback: () => void) {
        const start = Date.now()
        function fadeOutFrame() {
            const now = Date.now()
            const elapsed = now - start
            const value = Math.pow(Math.max(0, 1 - elapsed / 500), 4)
            setOpacity(value)
            if (value > 0) requestAnimationFrame(fadeOutFrame)
            else callback()
        }
        fadeOutFrame()
    }
    function fadeIn() {
        const start = Date.now()

        function fadeInFrame() {
            const now = Date.now()
            const elapsed = now - start
            const value = Math.pow(Math.min(1, elapsed / 500), 4)
            setOpacity(value)
            if (value < 1) requestAnimationFrame(fadeInFrame)
        }
        requestAnimationFrame(fadeInFrame)
    }

    async function launchSimulation(simId2: Uuid<"Simulation">, continueFromSave: boolean) {
        if (continueFromSave) {
            setRun(await postRunSession(simId2))
        } else {
            setRun(await postRun(simId2))
        }
        fadeOut(() => setSimId(simId2))
    }

    async function quitSimulation(shouldConfirm: boolean, personalityTypes?: string[]) {
        if (shouldConfirm) {
            if (
                !confirm(
                    "Are you sure you want to quit?\n\nYour progress in this scenario will be lost."
                )
            ) {
                return
            }
        }
        setSimId(null)
        await modules.refresh()

        if (personalityTypes) {
            setSelectPersonalityType(personalityTypes)
        } else {
            fadeIn()
        }
    }

    if (simId && run) {
        return (
            <div style={{ backgroundColor: "#333", height: "100%" }}>
                <SimulationPlayerLoader simId={simId} run={run} exit={quitSimulation} />
            </div>
        )
    }
    if (quizId) {
        return (
            <QuizView
                id={quizId}
                exit={async () => {
                    await modules.refresh()
                    setQuizId(null)
                    fadeIn()
                }}
            />
        )
    }
    if (surveyId) {
        return (
            <SurveyView
                id={surveyId}
                exit={async () => {
                    await modules.refresh()
                    setSurveyId(null)
                    fadeIn()
                }}
            />
        )
    }
    if (selectPersonalityType) {
        return (
            <SelectPersonalityTypeView
                personalityTypeCandidates={selectPersonalityType}
                exit={async () => {
                    await modules.refresh()
                    setSelectPersonalityType(undefined)
                    fadeIn()
                }}
            />
        )
    }
    if (videoStep) {
        return (
            <VideoView
                step={videoStep}
                exit={async () => {
                    await modules.refresh()
                    setVideoStep(null)
                    fadeIn()
                }}
            />
        )
    }

    const context: HomeContext = {
        refresh: modules.refresh,
        modules: modules.data.modules,
        customer: modules.data.customer,
        user: modules.data.user,
        disableDashboard: modules.data.disableDashboard,
        disableLeaderboards: modules.data.disableLeaderboards,
        ongoingModule: modules.data.ongoingModule,
        articlesInFocus: modules.data.articlesInFocus,
        surveysInFocus: modules.data.surveysInFocus,
        simulationsInFocus: modules.data.simulationsInFocus,
        launchSimulation,
        launchSurvey: (id) =>
            fadeOut(() => {
                setQuizId(null)
                setSurveyId(id)
                setVideoStep(null)
            }),
        launchQuiz: (id) =>
            fadeOut(() => {
                setQuizId(id)
                setSurveyId(null)
                setVideoStep(null)
            }),
        launchVideo: (step) =>
            fadeOut(() => {
                setQuizId(null)
                setSurveyId(null)
                setVideoStep(step)
            }),
    }

    return (
        <div style={{ backgroundColor: "#333", height: "100%" }}>
            <div
                style={{
                    opacity,
                    height: "100%",
                    transform: `translateX(${-1000 * (1 - Math.pow(opacity, 0.125))}px)`,
                }}>
                <MenuBar
                    direction="vertical"
                    user={modules.data.user}
                    disableDashboard={modules.data.disableDashboard}
                    disableLeaderboards={modules.data.disableLeaderboards}>
                    <Outlet context={context} />
                </MenuBar>
            </div>
        </div>
    )
}

export function ModulePage(props: { innerTab?: "Individual" | "Facilitator" }) {
    const { id } = useParams()
    const {
        modules,
        user,
        customer,
        ongoingModule,
        launchSimulation,
        launchQuiz,
        launchSurvey,
        launchVideo,
    } = useOutletContext<HomeContext>()

    let moduleIndex =
        id !== undefined
            ? modules.findIndex((m) => m.id.toString() === id)
            : modules.findIndex((m) => m.id === (ongoingModule ?? modules[0]?.id))

    if (moduleIndex === -1 && modules.length > 0) moduleIndex = 0

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                flex: 1,
                height: "100%",
            }}>
            <ModuleList modules={modules} moduleIndex={moduleIndex} />
            {moduleIndex !== -1 ? (
                <ModuleDetails
                    mod={modules[moduleIndex]}
                    customer={customer}
                    innerTab={props.innerTab}
                    user={user}
                    launchSimulation={launchSimulation}
                    launchQuiz={launchQuiz}
                    launchSurvey={launchSurvey}
                    launchVideo={launchVideo}
                />
            ) : undefined}
        </div>
    )
}

function ModuleList({
    modules,
    moduleIndex,
}: {
    modules: GetModulesModulesDto[]
    moduleIndex: number
}) {
    const localize = useLocalize()
    const tabs = Array.from(new Set(modules.map((m) => localize(m.group))))
    const tabIndex = tabs.findIndex((tab) => tab === localize(modules[moduleIndex].group))
    const navigate = useNavigate()

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                fontWeight: "bold",
                borderRight: "1px solid #EAECF0",
                boxShadow: "0px 4px 20px 0px #BFBDB029",
                height: "100%",
            }}>
            <div style={{ paddingTop: 32, paddingLeft: 32, height: "100%", overflowY: "auto" }}>
                <div style={{ marginBottom: 24, fontSize: 24 }}>Learning modules</div>

                <div
                    style={{
                        marginBottom: 24,
                        display: "flex",
                        flexDirection: "row",
                        fontSize: 14,
                    }}>
                    {tabs.map((tab, i) => (
                        <div
                            onClick={() => {
                                const firstModule = modules.find(
                                    (m) => localize(m.group) === tabs[i] && m.canPlay
                                )
                                if (firstModule) navigate(`/modules/${firstModule.id}`)
                            }}
                            style={{
                                marginRight: 32,
                                padding: 4,
                                transition: "all 0.2s",
                                color: i === tabIndex ? "black" : "#B0B9BF",
                                cursor: "pointer",
                            }}>
                            <div style={{ paddingLeft: 8, paddingRight: 8 }}>{tab}</div>
                            {i === tabIndex && (
                                <div
                                    style={{
                                        width: "100%",
                                        backgroundColor: darkMustard,
                                        height: 2,
                                        marginTop: 6,
                                    }}
                                />
                            )}
                        </div>
                    ))}
                </div>
                <div style={{ paddingRight: 32 }}>
                    {modules
                        .filter((m) => localize(m.group) === tabs[tabIndex])
                        .map((mod) => {
                            const i = modules.indexOf(mod)
                            return (
                                <ModuleCard
                                    mod={mod}
                                    selected={i === moduleIndex}
                                    onClick={() => navigate(`/modules/${mod.id}`)}
                                />
                            )
                        })}
                </div>
            </div>
        </div>
    )
}

function ModuleDetails({
    mod,
    customer,
    innerTab,
    user,
    launchSimulation,
    launchQuiz,
    launchSurvey,
    launchVideo,
}: {
    mod: GetModulesModulesDto
    customer: GetModulesCustomerDto
    innerTab?: "Individual" | "Facilitator"
    user: GetModulesUserDto
    launchSimulation: (sim: Uuid<"Simulation">, startFromSave: boolean) => void
    launchQuiz: (id: GetQuizDto["id"]) => void
    launchSurvey: (id: Survey["id"]) => void
    launchVideo: (id: ModuleStepVideoDto) => void
}) {
    const localize = useLocalize()
    const navigate = useNavigate()
    const [localSession, setLocalSession] = useLocalSession(mod.id)
    const session = useSession(mod.id, localSession?.sessionCode ?? null)
    const badges = mod.badges.filter((b) => b.earned !== undefined).length
    const maxBadges = mod.badges.length
    const showFacilitatorFeatures =
        user.isFacilitator && user.isFacilitatorFeaturesEnabled && mod.facilitatorSteps.length > 0
    const tabs = ["Facilitator", "Individual"]
    const tabIndex =
        innerTab && showFacilitatorFeatures
            ? tabs.findIndex((tab) => tab.toLocaleLowerCase() === innerTab.toLocaleLowerCase())
            : 1

    return (
        <div style={{ flex: 1, height: "100%", backgroundColor: yellowBG, overflowY: "auto" }}>
            <div style={{ display: "flex", flexDirection: "column", marginBottom: 32 }}>
                <div
                    style={{
                        padding: 32,
                        display: "flex",
                        flexDirection: "row",
                        backgroundColor: mod.color,
                        transition: "all 0.4s ease",
                        alignItems: "center",
                    }}>
                    <div
                        style={{
                            fontSize: 24,
                            fontWeight: "bold",
                            color: "white",
                        }}>
                        {localize(mod.title ?? mod.name)}
                    </div>
                    <div
                        style={{
                            flex: 1,
                            fontSize: 16,
                            textAlign: "right",
                            color: "white",
                        }}>
                        {mod.requiresSession && (
                            <SessionToolbar
                                mod={mod}
                                customer={customer}
                                localSession={localSession}
                                setLocalSession={setLocalSession}
                                isFacilitator={showFacilitatorFeatures}
                            />
                        )}
                    </div>
                </div>

                {showFacilitatorFeatures && (
                    <div
                        style={{
                            marginTop: 32,
                            marginLeft: 20,
                            marginRight: 20,
                            display: "flex",
                            flexDirection: "row",
                            fontSize: 22,
                            fontWeight: "bold",
                        }}>
                        {tabs.map((tab, i) => (
                            <div
                                onClick={() => {
                                    navigate(`/modules/${mod.id}/${tab.toLocaleLowerCase()}`)
                                }}
                                style={{
                                    marginRight: 32,
                                    padding: 4,
                                    transition: "all 0.2s",
                                    color: i === tabIndex ? "black" : "#B0B9BF",
                                    cursor: "pointer",
                                }}>
                                <div style={{ paddingLeft: 8, paddingRight: 8 }}>{tab}</div>
                                {i === tabIndex && (
                                    <div
                                        style={{
                                            width: "100%",
                                            backgroundColor: mod.color,
                                            height: 2,
                                            marginTop: 6,
                                        }}
                                    />
                                )}
                            </div>
                        ))}
                    </div>
                )}

                {showFacilitatorFeatures && tabIndex === 0 && (
                    <div>
                        {mod.facilitatorDescription && (
                            <div style={{ padding: 32, display: "flex", flexDirection: "row" }}>
                                <div style={{ maxWidth: 600, marginRight: 32 }}>
                                    <MarkdownView
                                        value={ellipsize(
                                            localize(mod.facilitatorDescription)?.valueOf() ?? "",
                                            160
                                        )}
                                    />

                                    <div
                                        style={{
                                            backgroundColor: "#EAEAEA",
                                            height: 1,
                                            width: "100%",
                                            marginBottom: 18,
                                            marginTop: 18,
                                        }}
                                    />
                                </div>
                            </div>
                        )}

                        {mod.facilitatorSteps.map((step, i) =>
                            mapFacilitatorStep(mod.facilitatorSteps, step, i)
                        )}
                    </div>
                )}

                {tabIndex === 1 && (
                    <>
                        <div style={{ padding: 32, display: "flex", flexDirection: "row" }}>
                            <div style={{ flex: 1 }}>
                                <div style={{ maxWidth: 600, marginRight: 32 }}>
                                    <MarkdownView
                                        value={
                                            mod.introduction ??
                                            ellipsize(
                                                localize(mod.description).valueOf() ?? "",
                                                160
                                            )
                                        }
                                    />
                                    <div
                                        onClick={async () => {
                                            await ReadMoreModal(mod, localize)
                                        }}
                                        style={{
                                            fontWeight: "bold",
                                            marginTop: 12,
                                            cursor: "pointer",
                                        }}>
                                        Read more &gt;
                                    </div>
                                    <div
                                        style={{
                                            backgroundColor: "#EAEAEA",
                                            height: 1,
                                            width: "100%",
                                            marginBottom: 18,
                                            marginTop: 18,
                                        }}
                                    />
                                    {mod.characterIntroduction && (
                                        <div
                                            style={{
                                                textDecoration: "underline",
                                                cursor: "pointer",
                                            }}
                                            onClick={() => {
                                                if (mod.characterIntroduction)
                                                    launchSimulation(
                                                        mod.characterIntroduction,
                                                        false
                                                    )
                                            }}>
                                            <PlayIcon
                                                color={mod.color}
                                                style={{
                                                    width: 20,
                                                    height: 24,
                                                    marginBottom: -8,
                                                    marginLeft: 10,
                                                    marginRight: 10,
                                                }}
                                            />
                                            Watch the Character Introduction
                                        </div>
                                    )}
                                    <div
                                        style={{
                                            marginTop: 18,
                                        }}>
                                        {mod.characters.map((c, i) => {
                                            return (
                                                <div
                                                    key={c.id.toString()}
                                                    style={{ display: "inline" }}>
                                                    <img
                                                        src={c.portrait}
                                                        style={{
                                                            width: 40,
                                                            height: 40,
                                                            borderRadius: 25,
                                                            marginRight: 5,
                                                            marginBottom: 5,
                                                            border: `2px solid ${mod.color}`,
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={async () => {
                                                            await CharacterModal(mod, i)
                                                        }}
                                                    />
                                                </div>
                                            )
                                        })}
                                    </div>
                                    {mod.characters.length > 0 && (
                                        <div style={{ marginTop: 8 }}>
                                            Get to know each character by clicking their avatar.
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div style={{ flex: 0.6, display: "flex", flexDirection: "column" }}>
                                {mod.maxScore > 0 && (
                                    <div style={{ flex: 1, display: "flex", flexDirection: "row" }}>
                                        <div style={{ position: "relative", width: 120 }}>
                                            <svg>
                                                <PartialCircle
                                                    offset={10}
                                                    radius={40}
                                                    percent={100}
                                                    strokeWidth={13}
                                                    color={mod.color + "55"}
                                                    animate={false}
                                                />
                                                <PartialCircle
                                                    offset={10}
                                                    radius={40}
                                                    percent={(mod.score / mod.maxScore) * 100}
                                                    strokeWidth={13}
                                                    color={mod.color}
                                                    animate={true}
                                                />
                                            </svg>
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    top: 0,
                                                    left: 0,
                                                    width: 100,
                                                    height: 100,
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    fontWeight: "bold",
                                                }}>
                                                {mod.score}
                                            </div>
                                        </div>

                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                margin: 8,
                                            }}>
                                            <div
                                                style={{
                                                    fontWeight: "bold",
                                                    fontSize: 20,
                                                    marginBottom: 16,
                                                }}>
                                                Your progress
                                            </div>
                                            {mod.score ? (
                                                <div>
                                                    Score: {mod.score} / {mod.maxScore}
                                                </div>
                                            ) : (
                                                <div>Not started</div>
                                            )}
                                            {maxBadges ? (
                                                <div>
                                                    Badges: {badges} / {maxBadges}{" "}
                                                </div>
                                            ) : undefined}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div style={{ display: "flex", flexDirection: "row", margin: "0 32" }}>
                            <div style={{ fontSize: 22, fontWeight: 700, marginLeft: 32 }}>
                                Learning Path
                            </div>
                            <div
                                style={{
                                    flex: 1,
                                    textAlign: "right",
                                    fontSize: 14,
                                    fontWeight: 600,
                                    paddingTop: 6,
                                    marginRight: 32,
                                }}>
                                {mod.steps.length} tasks
                                <span style={{ margin: "0 6" }}> • </span>
                                {mod.steps
                                    .map((step) => {
                                        if (step.type === "Quiz") return step.minutesToComplete ?? 0
                                        else if (step.type === "Simulation")
                                            return step.minutesToComplete ?? 0
                                        else if (step.type === "Survey")
                                            return step.minutesToComplete ?? 0
                                        else if (step.type === "Article")
                                            return step.minutesToComplete ?? 0
                                        else return 0
                                    })
                                    .reduce((prev, curr) => prev + curr, 0)}{" "}
                                min
                            </div>
                        </div>
                        <div>{mod.steps.map((step, i) => mapModuleStep(mod.steps, step, i))}</div>
                        <ActionCardsSection mod={mod} sessionData={session.data} />
                    </>
                )}
            </div>
        </div>
    )

    function mapModuleStep(
        steps: ModuleStepDtoBase[],
        step: ModuleStepDto,
        i: number,
        isFacilitatorStep = false
    ) {
        if (step.type === "Quiz") {
            return (
                <ModuleStepBox
                    key={i}
                    title={step.title}
                    description={step.description}
                    onClick={() => launchQuiz(step.quizId)}
                    isFacilitatorStep={isFacilitatorStep}
                    icon={isFacilitatorStep ? getFacilitatorStepIcon(i) : <QuizIcon />}
                    isLast={i === steps.length - 1}
                    color={mod.color}
                    disabled={!step.canPlay}
                    nextDisabled={steps[i + 1]?.canPlay === false}
                    score={step.score}
                    maxScore={step.scoreMax}
                    minutesToComplete={step.minutesToComplete}
                />
            )
        } else if (step.type === "Simulation") {
            return (
                <ModuleStepBox
                    key={i}
                    title={step.title}
                    description={step.description}
                    onClick={(startFromSave: boolean) => {
                        launchSimulation(step.simulationId, startFromSave)
                    }}
                    isFacilitatorStep={isFacilitatorStep}
                    icon={isFacilitatorStep ? getFacilitatorStepIcon(i) : <PlayIcon />}
                    isLast={i === steps.length - 1}
                    color={mod.color}
                    disabled={!step.canPlay}
                    nextDisabled={steps[i + 1]?.canPlay === false}
                    score={step.score}
                    maxScore={step.scoreMax}
                    savedGame={step.savedGame}
                    minutesToComplete={step.minutesToComplete}
                />
            )
        } else if (step.type === "Survey") {
            return (
                <ModuleStepBox
                    key={i}
                    title={step.title}
                    description={step.description}
                    onClick={() => {
                        launchSurvey(step.surveyId)
                    }}
                    isFacilitatorStep={isFacilitatorStep}
                    icon={isFacilitatorStep ? getFacilitatorStepIcon(i) : <SurveyIcon />}
                    isLast={i === steps.length - 1}
                    color={mod.color}
                    disabled={!step.canPlay}
                    nextDisabled={steps[i + 1]?.canPlay === false}
                    score={0}
                    maxScore={0}
                    minutesToComplete={step.minutesToComplete}
                />
            )
        } else if (step.type === "Article") {
            return (
                <ModuleStepBox
                    key={i}
                    title={step.title}
                    description={step.description}
                    buttonText={"Click to read"}
                    onClick={() => {
                        navigate(`/articles/${step.articleId}`)
                    }}
                    isFacilitatorStep={isFacilitatorStep}
                    icon={isFacilitatorStep ? getFacilitatorStepIcon(i) : <ArticleIcon />}
                    isLast={i === steps.length - 1}
                    color={mod.color}
                    disabled={!step.canPlay}
                    nextDisabled={steps[i + 1]?.canPlay === false}
                    score={0}
                    maxScore={0}
                    minutesToComplete={step.minutesToComplete}
                />
            )
        } else if (step.type === "Link") {
            return (
                <ModuleStepBox
                    key={i}
                    title={step.title}
                    description={step.description}
                    buttonText={"Click to open"}
                    onClick={() => {
                        window.open(step.url)
                    }}
                    isFacilitatorStep={isFacilitatorStep}
                    icon={isFacilitatorStep ? getFacilitatorStepIcon(i) : <ArticleIcon />}
                    isLast={i === steps.length - 1}
                    color={mod.color}
                    disabled={!step.canPlay}
                    nextDisabled={steps[i + 1]?.canPlay === false}
                    score={0}
                    maxScore={0}
                    minutesToComplete={step.minutesToComplete}
                />
            )
        } else if (step.type === "Video") {
            return (
                <ModuleStepBox
                    key={i}
                    title={step.title}
                    description={step.description}
                    buttonText={"Click to open"}
                    onClick={() => {
                        launchVideo(step)
                    }}
                    isFacilitatorStep={isFacilitatorStep}
                    icon={isFacilitatorStep ? getFacilitatorStepIcon(i) : <VideoIcon />}
                    isLast={i === steps.length - 1}
                    color={mod.color}
                    disabled={!step.canPlay}
                    nextDisabled={steps[i + 1]?.canPlay === false}
                    score={0}
                    maxScore={0}
                    minutesToComplete={step.minutesToComplete}
                />
            )
        }
    }

    function mapFacilitatorStep(
        steps: ModuleStepDtoBase[],
        step: ModuleFacilitatorStepDto,
        i: number
    ) {
        if (step.type === "SelectActionCards") {
            return (
                <FacilitatorStepBox
                    key={i}
                    title={step.title}
                    description={step.description}
                    onClick={() => navigate(`/modules/${mod.id}/action-cards`)}
                    icon={getFacilitatorStepIcon(i)}
                    isLast={i === steps.length - 1}
                    color={mod.color}
                    disabled={!localSession}
                    selected={localSession && (session.data?.selectedActionCards?.length ?? 0) > 0}
                />
            )
        } else if (step.type === "PreparationArticle") {
            return (
                <FacilitatorStepBox
                    key={i}
                    title={step.title}
                    description={step.description}
                    onClick={() => {
                        navigate(`/articles/${step.articleId}`)
                    }}
                    icon={getFacilitatorStepIcon(i)}
                    isLast={i === steps.length - 1}
                    color={mod.color}
                    disabled={false}
                    article={true}
                />
            )
        } else {
            return mapModuleStep(steps, step, i, true)
        }
    }

    function getFacilitatorStepIcon(i: number) {
        return <div style={{ fontWeight: 700, color: "white" }}>{i + 1}</div>
    }
}

function CharacterModal(mod: GetModulesModulesDto, index: number) {
    const character = mod.characters[index]
    return Modal((close) => (
        <div
            style={{
                width: 550,
                maxWidth: "100%",
                backgroundColor: "white",
                borderRadius: 16,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyItems: "center",
            }}>
            <div
                style={{
                    paddingLeft: 48,
                    paddingRight: 48,
                }}>
                <div
                    style={{
                        textAlign: "center",
                    }}>
                    <img
                        src={character?.portrait}
                        style={{
                            width: 180,
                            height: 180,
                            borderRadius: 90,
                            border: `5px solid ${mod.color}`,
                            marginTop: 64,
                        }}
                    />
                    <div
                        style={{
                            fontSize: 22,
                            fontWeight: "bold",
                            marginTop: 32,
                        }}>
                        {character.name}
                    </div>
                    <div
                        style={{
                            fontSize: 16,
                            marginTop: 6,
                        }}>
                        {character.job ?? "No job title specified"}
                    </div>
                    <div
                        style={{
                            fontSize: 16,
                            marginTop: 36,
                            marginBottom: 48,
                        }}>
                        {character.description ?? "No description specified"}
                    </div>
                </div>
            </div>
            <div
                style={{
                    cursor: "pointer",
                    backgroundColor: mod.color,
                    color: "white",
                    textAlign: "center",
                    padding: 20,
                    borderRadius: 12,
                    width: 300,
                    fontSize: 18,
                    margin: "32 auto",
                    marginBottom: 32,
                }}
                onClick={async () => {
                    close(undefined)
                    await CharacterModal(mod, (index + 1) % mod.characters.length)
                }}>
                {"Next Character"}
            </div>
            <div
                onClick={close}
                style={{
                    cursor: "pointer",
                    fontSize: 18,
                    textAlign: "center",
                    textDecoration: "underline",
                    margin: "18 auto",
                    width: 80,
                    marginBottom: 48,
                }}>
                Close
            </div>
        </div>
    ))
}

function ReadMoreModal(
    mod: GetModulesModulesDto,
    localize: (text: Localized<string | Markdown>) => string | Markdown
) {
    return Modal((close) => (
        <div
            style={{
                backgroundColor: "white",
                padding: 8,
                borderRadius: 8,
                maxWidth: 550,
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                width: "100%",
                alignItems: "center",
            }}>
            <div
                style={{
                    width: 100,
                    height: 100,
                    margin: "0 auto",
                    marginTop: 64,
                    backgroundImage: `url(${mod.icon})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "contain",
                }}
            />
            <div
                style={{
                    fontSize: 32,
                    fontWeight: "bold",
                    marginTop: 32,
                }}>
                {localize(mod.title ?? mod.name)?.toLocaleUpperCase()}
            </div>
            <div style={{ margin: 48 }}>
                {mod.description && <MarkdownView value={localize(mod.description)} />}
            </div>
            <div
                onClick={close}
                style={{
                    cursor: "pointer",
                    fontSize: 18,
                    textAlign: "center",
                    textDecoration: "underline",
                    margin: "18 auto",
                    width: 80,
                    marginBottom: 48,
                }}>
                Close
            </div>
        </div>
    ))
}

function ActionCardsSection({
    mod,
    sessionData,
}: {
    mod: GetModulesModulesDto
    sessionData?: GetSessionDto
}) {
    const actionCards = useActionCards(mod.id)
    const localize = useLocalize()

    const selectedActionCards =
        sessionData?.selectedActionCards
            ?.map(
                (selected) =>
                    actionCards.data?.actionCards?.find((a) => a.id === selected) ??
                    sessionData?.customActionCards?.find((a) => a.id === selected)
            )
            .filter((a) => a !== undefined)
            .map((a) => a as ActionCard) ?? []
    const showActionCards = selectedActionCards.length >= 1

    return (
        <>
            {showActionCards && (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        margin: "0 32",
                    }}>
                    <div
                        style={{
                            fontSize: 18,
                            fontWeight: 600,
                            marginLeft: 32,
                            marginTop: 24,
                        }}>
                        Action Cards Chosen in Team Session
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            marginBottom: 24,
                            marginLeft: 20,
                            marginRight: 20,
                        }}>
                        {getColumns(2).map((column, i) => (
                            <div
                                key={i}
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    flex: 1,
                                }}>
                                {column.map(mapActionCard)}
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </>
    )

    function mapActionCard(actionCard: ActionCard) {
        const tip = actionCard.tips.length > 0 ? actionCard.tips[0] : undefined
        return (
            <div
                style={{
                    borderRadius: 16,
                    boxShadow: "0px 4px 20px 0px #BFBDB029",
                    padding: 24,
                    backgroundColor: "white",
                    border: "1px solid #FAF3E7",
                    display: "flex",
                    flexDirection: "column",
                    marginTop: 24,
                    marginLeft: 12,
                    marginRight: 12,
                }}>
                <div style={{ fontSize: 24, fontWeight: 700 }}>{localize(actionCard.title)}</div>
                <div style={{ marginTop: 16 }}>{localize(actionCard.body)}</div>
                {tip && (
                    <StepCardButton
                        onClick={() => ActionCardTipModal(actionCard, tip, mod, localize)}
                        disabled={false}
                        text={"Show tip"}
                    />
                )}
            </div>
        )
    }

    function getColumns(columnCount: number) {
        const columns: ActionCard[][] = []

        for (let i = 0; i < columnCount; i++) {
            columns.push([])
        }

        for (let i = 0; i < selectedActionCards.length; i++) {
            columns[i % columnCount].push(selectedActionCards[i])
        }

        return columns
    }
}

function PlayIcon(props: { color?: string; style?: CSSProperties }) {
    return (
        <svg
            width="25"
            height="28"
            viewBox="0 0 25 28"
            fill="none"
            style={props.style}
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M22.7485 11.4395C23.6486 11.9896 24.1962 12.9585 24.1962 14.0024C24.1962 15.0463 23.6486 16.0152 22.7485 16.5091L4.62363 27.5109C3.69085 28.1297 2.52331 28.1547 1.5704 27.6234C0.617291 27.0921 0.0270996 26.0919 0.0270996 25.0042V3.00056C0.0270996 1.91538 0.617291 0.914588 1.5704 0.38325C2.52331 -0.147463 3.69085 -0.125584 4.62363 0.440135L22.7485 11.4395Z"
                fill={props.color ?? "white"}
            />
        </svg>
    )
}

function QuizIcon() {
    return (
        <svg
            width="26"
            height="37"
            viewBox="0 0 26 37"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16.5994 0H7.8C3.49863 0 0 3.56353 0 7.94471C0 9.40703 1.16269 10.5185 2.6 10.5185C4.03731 10.5185 5.2 9.33338 5.2 7.94471C5.2 6.48487 6.36512 5.29647 7.8 5.29647H16.5994C18.915 5.29647 20.8 7.21562 20.8 9.58248C20.8 11.2145 19.9087 12.6834 18.3219 13.4994L10.3837 18.2389C9.51437 18.7189 9.1 19.5878 9.1 20.523V23.8333C9.1 25.2956 10.2627 26.4807 11.7 26.4807C13.1373 26.4807 14.3 25.2956 14.3 23.8333V22.0375L20.8 18.1479C24.0069 16.5134 26 13.2304 26 9.58248C26 4.29842 21.7831 0 16.5994 0ZM11.7 30.4539C9.90519 30.4539 8.45 31.9361 8.45 33.7642C8.45 35.5923 9.90519 37 11.7 37C13.4948 37 14.95 35.5186 14.95 33.7642C14.95 32.0097 13.4956 30.4539 11.7 30.4539Z"
                fill="white"
            />
        </svg>
    )
}

function SurveyIcon() {
    return (
        <svg
            width="19"
            height="14"
            viewBox="0 0 19 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18.6024 0.410857C19.1325 0.957792 19.1325 1.84164 18.6024 2.38857L7.74463 13.5898C7.21447 14.1367 6.35772 14.1367 5.82756 13.5898L0.397495 7.98919C-0.132498 7.44225 -0.132498 6.5584 0.397495 6.01147C0.927574 5.46454 1.78686 5.46454 2.31703 6.01147L6.74792 10.6188L16.6853 0.410857C17.2155 -0.136952 18.0722 -0.136952 18.6024 0.410857Z"
                fill="white"
            />
        </svg>
    )
}

function ArticleIcon() {
    return (
        <svg
            width="11"
            height="12"
            viewBox="0 0 11 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.83203 10.1673L10.1654 1.83398M10.1654 1.83398H1.83203M10.1654 1.83398V10.1673"
                stroke="white"
                strokeWidth="1.67"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

function VideoIcon() {
    return (
        <svg
            style={{ width: "55%" }}
            width="512"
            height="512"
            viewBox="0 0 512 512"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M362.012 24.3941L340.4 101.617L466.211 69.9922C466.211 69.9922 477.65 66.8275 480.137 62.3473C483.937 55.4993 479.723 45.9171 479.723 45.9171L470.67 9.90082C470.67 9.90082 469.719 3.10418 465.352 0.981784C461.683 -0.801037 457.51 0.389002 457.51 0.389002L362.012 24.3941ZM31.4586 179.3L133.713 153.597L155.324 76.3738L29.5135 107.999C29.5135 107.999 18.0747 111.163 15.5882 115.644C11.7876 122.492 16.0018 132.074 16.0018 132.074L28.021 179.889V248.925H136.934L176.719 179.3H31.4586ZM498.483 280.253H28.02V502.003C28.02 507.526 32.4972 512.003 38.02 512.003H488.483C494.005 512.003 498.483 507.526 498.483 502.003V280.253ZM111.304 345.902C111.304 337.113 118.429 329.988 127.218 329.988H399.748C408.538 329.988 415.663 337.113 415.663 345.902C415.663 354.691 408.538 361.816 399.748 361.816H127.218C118.429 361.816 111.304 354.691 111.304 345.902ZM111.304 436.41C111.304 427.62 118.429 420.495 127.218 420.495H399.748C408.538 420.495 415.663 427.62 415.663 436.41C415.663 445.199 408.538 452.324 399.748 452.324H127.218C118.429 452.324 111.304 445.199 111.304 436.41ZM349.287 249.417L389.073 179.793H487.542C487.542 179.793 491.879 179.656 495.002 182.279C498.72 185.402 497.985 192.226 497.985 192.226V229.362V249.417H479.012H349.287ZM313.978 248.925H166.772L205.065 179.3H353.763L313.978 248.925ZM164.35 147.683L307.115 111.796L328.726 34.5735L184.515 70.8241L164.35 147.683Z"
                fill="white"
            />
        </svg>
    )
}
