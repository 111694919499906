import React from "react"
import { useHover } from "../../../packages/hooks/useHover"
import { darkMustard } from "./colors"

export function ExitButton(props: { onClick: () => void; text?: string }) {
    const { hover, hoverProps } = useHover()
    return (
        <div
            onClick={props.onClick}
            {...hoverProps}
            style={{
                position: "absolute",
                cursor: "pointer",
                top: 16,
                left: 16,
                width: 80,
                zIndex: 1,
                fontWeight: 400,
                backgroundColor: "#131D22B6",
                color: hover ? darkMustard : "white",
                padding: 12,
                borderRadius: 12,
                display: "flex",
                flexDirection: "row",
                justifyItems: "center",
                transform: hover ? "scale(1.1)" : undefined,
                transition: "all 0.165s ease",
            }}>
            <ArrowLeft hover={hover} />
            {props.text ?? "Exit"}
        </div>
    )
}
export function ArrowLeft(props: { hover: boolean; fill?: string }) {
    return (
        <svg
            width="21"
            height="18"
            viewBox="0 0 21 18"
            fill={props.fill ?? (props.hover ? darkMustard : "white")}
            style={{ marginRight: 8, transition: "all 0.165s ease" }}
            xmlns="http://www.w3.org/2000/svg">
            <path d="M20.9589 9.00019C20.9589 9.62133 20.4545 10.1257 19.8333 10.1257H3.93272L10.1535 16.0605C10.6047 16.4897 10.6208 17.2048 10.1915 17.6516C9.76233 18.1013 9.05053 18.1174 8.6005 17.6897L0.349932 9.81411C0.125971 9.60492 0 9.30959 0 9.00019C0 8.69079 0.125971 8.40015 0.348643 8.18451L8.59921 0.308968C9.04713 -0.117156 9.75968 -0.101217 10.191 0.348814C10.6202 0.794157 10.6041 1.50905 10.1529 1.93986L3.93213 7.87464H19.8754C20.4948 7.87511 20.9589 8.3814 20.9589 9.00019Z" />
        </svg>
    )
}
