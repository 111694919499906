import React from "react"
import { useOutletContext } from "react-router-dom"
import { useLocalize } from "../../../packages/localization/client-side/useLocalize"
import { Modal } from "../../../packages/modal/Modal"
import { GetModulesModulesBadgesDto, GetModulesModulesDto } from "./client"
import { HomeContext } from "./Home"

export function BadgesPage() {
    const { modules } = useOutletContext<HomeContext>()
    const localize = useLocalize()
    const allBadges = modules.reduce(
        (sum, m) => [...sum, ...m.badges],
        [] as GetModulesModulesBadgesDto[]
    )
    const earnedBadges = allBadges.filter((b) => b.earned !== undefined)

    return (
        <div
            style={{
                flex: 1,
                height: "100%",
                backgroundColor: "#FEFBF7",
                overflowY: "auto",
            }}>
            <div style={{ marginTop: 36, paddingLeft: 43 }}>
                <div style={{ fontSize: 22 }}>
                    Your badges ({earnedBadges.length}/{allBadges.length})
                </div>
            </div>
            {modules.map((module) => (
                <>
                    <div
                        style={{
                            width: 700,
                            height: 54,
                            background: "#FAF3E7",
                            borderTopRightRadius: 27,
                            borderBottomRightRadius: 27,
                            fontSize: 22,
                            fontWeight: 700,
                            display: "flex",
                            alignItems: "center",
                            marginTop: 36,
                            paddingLeft: 43,
                        }}>
                        {localize(module.title ?? module.name).toLocaleUpperCase()}
                        <span style={{ fontWeight: 400, marginLeft: 8 }}>
                            ({module.badges.filter((b) => b.earned !== undefined).length}/
                            {module.badges.length})
                        </span>
                    </div>
                    {module.badges.map((badge, i) => (
                        <BadgeRow
                            module={module}
                            badge={badge}
                            isLast={i === module.badges.length - 1}
                        />
                    ))}
                </>
            ))}
        </div>
    )
}

function BadgeRow({
    module,
    badge,
    isLast,
}: {
    module: GetModulesModulesDto
    badge: GetModulesModulesBadgesDto
    isLast: boolean
}) {
    const localize = useLocalize()

    return (
        <div
            key={badge.id.toString()}
            style={{
                marginTop: 36,
                marginLeft: 43,
                width: 700,
            }}>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                }}>
                <div
                    style={{
                        width: 40,
                        height: 46,
                        marginRight: 16,
                        opacity: badge.earned ? 1.0 : 0.5,
                    }}>
                    <div
                        style={{
                            position: "absolute",
                            width: 40,
                            height: 46,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}>
                        <BadgeBackgroundWithGradient color={module.color} />
                    </div>
                    <div
                        style={{
                            position: "absolute",
                            width: 40,
                            height: 46,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}>
                        <div
                            style={{
                                width: 25,
                                height: 25,
                                backgroundColor: "white",
                                WebkitMask: `url(${badge.icon}) no-repeat center`,
                                WebkitMaskSize: "contain",
                                mask: `url(${badge.icon}) no-repeat center`,
                                maskSize: "contain",
                            }}
                        />
                    </div>
                </div>
                <div style={{ flex: 1, paddingTop: 2, paddingRight: 12 }}>
                    <div style={{ fontSize: 18, fontWeight: 700 }}>{localize(badge.name)}</div>
                    <div style={{ fontSize: 12, fontWeight: 400 }}>
                        {badge.earned ? `Earned ${badge.earned}` : "Not earned yet"}
                    </div>
                </div>
                <div style={{ flex: 1, paddingTop: 2, paddingRight: 12 }}>
                    <div style={{ fontSize: 12, fontWeight: 400 }}>
                        {badge.description ? localize(badge.description) : null}
                    </div>
                </div>
            </div>
            {!isLast && (
                <div
                    style={{
                        width: "100%",
                        height: 1,
                        backgroundColor: "#F2E7D1",
                        marginTop: 36,
                    }}
                />
            )}
        </div>
    )
}

export function BadgeBackground({
    color,
    width,
    height,
}: {
    color?: string
    width?: number
    height?: number
}) {
    return (
        <svg
            width={width?.toString() ?? "40"}
            height={height?.toString() ?? "46"}
            viewBox="0 0 40 46"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 15.0302C0 12.7759 1.16964 10.6742 3.08571 9.50665L16.6607 1.1989C18.7143 -0.0584869 21.2857 -0.0584869 23.3393 1.1989L36.9107 9.50665C38.8304 10.6742 40 12.7759 40 15.0302V30.5589C40 32.7324 38.8304 34.834 36.9107 36.0016L23.3393 44.313C21.2857 45.5702 18.7143 45.5702 16.6607 44.313L3.08571 36.0016C1.16964 34.834 0 32.7324 0 30.5589L0 15.0302Z"
                fill={color ?? "#FAF3E7"}
            />
        </svg>
    )
}

function BadgeBackgroundWithGradient({
    color,
    width,
    height,
}: {
    color: string
    width?: number
    height?: number
}) {
    return (
        <svg
            width={width?.toString() ?? "40"}
            height={height?.toString() ?? "45"}
            viewBox="0 0 40 45"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.60607 10.1047L3.60771 10.1037L17.1827 1.79599L17.1829 1.79587C18.916 0.734711 21.084 0.734711 22.8171 1.79587L22.8172 1.79593L36.3886 10.1037L36.3911 10.1052C38.0088 11.0891 39 12.8648 39 14.7743V30.3031C39 32.1248 38.0147 33.9038 36.3911 34.8914L36.3885 34.893L22.8172 43.2042C22.8171 43.2043 22.8171 43.2043 22.817 43.2043C21.0839 44.2653 18.916 44.2652 17.1829 43.2042C17.1829 43.2042 17.1828 43.2042 17.1828 43.2042L3.60787 34.8929L3.60607 34.8918C1.98552 33.9043 1 32.125 1 30.3031L1 14.7743C1 12.8646 1.99145 11.0886 3.60607 10.1047Z"
                fill={color}
                stroke="url(#paint0_linear_1836_11567)"
                strokeWidth="2"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_1836_11567"
                    x1="-5"
                    y1="50"
                    x2="42"
                    y2="4.5"
                    gradientUnits="userSpaceOnUse">
                    <stop stop-color="white" stop-opacity="0" />
                    <stop offset="1" stop-color="white" stop-opacity="0.6" />
                </linearGradient>
            </defs>
        </svg>
    )
}

export function BadgeModal(badge: GetModulesModulesBadgesDto, module: GetModulesModulesDto) {
    const menuGrey = "#919394"
    const width = 300
    const height = (300 * 46) / 40
    const localize = useLocalize()
    return Modal((close) => (
        <div
            style={{
                backgroundColor: "white",
                padding: 8,
                borderRadius: 8,
                minWidth: 500,
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                width: "100%",
            }}>
            <div
                style={{
                    margin: "0 auto",
                    marginTop: 64,
                    position: "relative",
                    width,
                    height,
                }}>
                <div
                    style={{
                        position: "absolute",
                        width,
                        height,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        opacity: 0.25,
                    }}>
                    <BadgeBackground color={module.color} width={width} height={height} />
                </div>
                <div
                    style={{
                        position: "absolute",
                        width,
                        height,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                    <BadgeBackgroundWithGradient
                        color={module.color}
                        width={190}
                        height={(190 * 45) / 40}
                    />
                </div>
                <div
                    style={{
                        position: "absolute",
                        width,
                        height,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                    <div
                        style={{
                            width: 190 - 80,
                            height: (190 * 45) / 40 - 80,
                            backgroundColor: "white",
                            WebkitMask: `url(${badge.icon}) no-repeat center`,
                            WebkitMaskSize: "contain",
                            mask: `url(${badge.icon}) no-repeat center`,
                            maskSize: "contain",
                        }}
                    />
                </div>
            </div>
            <div
                style={{
                    marginTop: 32,
                    fontSize: 22,
                    fontWeight: 700,
                }}>
                {localize(badge.name)}
            </div>
            <div
                style={{
                    marginTop: 8,
                    fontSize: 16,
                    fontWeight: 400,
                    color: menuGrey,
                }}>
                Earned {badge.earned}
            </div>
            <div
                style={{
                    margin: 16,
                    fontSize: 16,
                    fontWeight: 400,
                }}>
                {badge.description && localize(badge.description)}
            </div>
            <Button
                color={module.color}
                onClick={close}
                style={{ marginTop: 72, marginBottom: 50 }}>
                Close
            </Button>
        </div>
    ))
}

function Button(props: {
    color: string
    onClick: (result: unknown) => void
    children: React.ReactNode
    disabled?: boolean
    style?: React.CSSProperties
}) {
    return (
        <div
            style={{
                cursor: "pointer",
                backgroundColor: props.color,
                opacity: props.disabled ? 0.5 : 1,
                color: "white",
                textAlign: "center",
                padding: 20,
                borderRadius: 12,
                width: 300,
                fontSize: 18,
                margin: "18 auto",
                ...props.style,
            }}
            onClick={props.disabled ? undefined : props.onClick}>
            {props.children}
        </div>
    )
}
