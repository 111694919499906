import React from "react"
import { useState } from "react"
import {
    ActionCardLikertScale,
    LikertScale,
    postSurveyResponse,
    SurveyActionCard,
    SurveyActionCardResponse,
    SurveyFreeTextResponse,
    SurveyInfo,
    SurveyLikertScaleResponse,
    SurveyMultipleChoice,
    SurveyMultipleChoiceOption,
    SurveyMultipleChoiceResponse,
    SurveyMultipleChoiceSelectedOption,
    SurveyQuestion,
    SurveyStatement,
    SurveyStep,
    SurveyStepResponse,
    useSurvey,
} from "./client"
import { Uuid } from "../../../reactor/Types/Primitives"
import { darkMustard, yellowBG } from "./colors"
import { ExitButton } from "./ExitButton"
import ReactDOM from "react-dom"
import { ProgressBar } from "./ProgressBar"
import { SubmitButton } from "./SubmitButton"
import { Modal } from "../../../packages/modal/Modal"
import { ModalButton } from "./components"
import { CheckCircle } from "./icons"
import { useLocalize } from "../../../packages/localization/client-side/useLocalize"

export function SurveyView(props: { id: Uuid<"Survey">; exit: () => void }) {
    const { data: survey, error } = useSurvey(props.id)

    const [submitButtonPortal, setSumbitButtonPortal] = useState<HTMLDivElement | null>(null)

    const [progress, setProgress] = useState(0)
    const [responses] = useState([] as SurveyStepResponse[])
    const [stepsQueue] = useState([] as SurveyStep[])
    const localize = useLocalize()

    async function advance() {
        setProgress(progress + 1)
        if (progress + 1 >= (stepsQueue.length ?? 0)) {
            await postSurveyResponse(props.id, {
                steps: responses,
            })
            await SurveyCompletedModal(props.exit)
        }
    }

    function content() {
        if (!step || !survey || !submitButtonPortal) return <></>
        switch (step.type) {
            case "Info":
                return (
                    <SurveyInfoView
                        submitButtonPortal={submitButtonPortal}
                        key={progress}
                        step={step}
                        advance={advance}
                    />
                )
            case "Statement":
            case "Question":
                switch (step.answerType) {
                    case "Free Text":
                        return (
                            <SurveyFreeTextView
                                submitButtonPortal={submitButtonPortal}
                                key={progress}
                                step={step}
                                advance={async (response: SurveyFreeTextResponse) => {
                                    responses.push(response)
                                    await advance()
                                }}
                            />
                        )
                    case "Likert Scale":
                        return (
                            <SurveyLikertScaleView
                                submitButtonPortal={submitButtonPortal}
                                key={progress}
                                step={step}
                                advance={async (response: SurveyLikertScaleResponse) => {
                                    responses.push(response)
                                    await advance()
                                }}
                            />
                        )
                    default: // Multiple choice is an object
                        return (
                            <SurveyMultipleChoiceView
                                submitButtonPortal={submitButtonPortal}
                                key={progress}
                                step={step}
                                advance={async (response: SurveyMultipleChoiceResponse) => {
                                    responses.push(response)
                                    await advance()
                                }}
                            />
                        )
                }
            case "Simulation":
                return (
                    <div>
                        <div>Simulations not supported yet</div>
                        <button onClick={() => setProgress(progress + 1)}>Next</button>
                    </div>
                )
            case "Action Card":
                return (
                    <SurveyActionCardView
                        submitButtonPortal={submitButtonPortal}
                        key={progress}
                        step={step}
                        advance={async (response: SurveyActionCardResponse) => {
                            responses.push(response)
                            await advance()
                        }}
                    />
                )
        }
    }

    if (error) return <>Error: {JSON.stringify(error)}</>
    if (!survey) return <>No survey: {props.id}</>

    if (stepsQueue.length === 0 && survey.steps.length > 0) {
        stepsQueue.push(...survey.steps)
    }

    const step: SurveyStep | undefined = stepsQueue[progress]
    const percent = (progress / stepsQueue.length) * 100

    return (
        <div
            style={{
                width: "100%",
                height: "100%",
                backgroundColor: yellowBG,
                position: "relative",
                overflow: "hidden",
            }}>
            <ExitButton onClick={props.exit} />
            <div
                style={{
                    overflowY: "auto",
                    width: "100%",
                    height: "100%",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                    paddingTop: 32,
                }}>
                {step && (
                    <>
                        <div
                            style={{
                                fontSize: 32,
                                textAlign: "center",
                                marginBottom: 64,
                            }}>
                            {localize(survey.name)}
                        </div>
                        <div style={{ maxWidth: 800, width: "100%" }}>
                            <div key={step.id.valueOf()}>{content()}</div>
                        </div>
                        <ProgressBar
                            percent={percent}
                            text={`${progress}/${stepsQueue.length} Complete`}>
                            <div ref={(r) => setSumbitButtonPortal(r)}>
                                <div style={{ height: 0, width: 0 }} />
                            </div>
                        </ProgressBar>
                    </>
                )}
            </div>
        </div>
    )
}

function SurveyInfoView({
    step,
    submitButtonPortal,
    advance,
}: {
    step: SurveyInfo
    submitButtonPortal: HTMLDivElement
    advance: () => void
}) {
    const localize = useLocalize()
    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ fontSize: 24, fontWeight: "bold", textAlign: "center" }}>
                {localize(step.text)}
            </div>
            {ReactDOM.createPortal(
                <SubmitButton
                    style={{
                        marginLeft: 32,
                    }}
                    onClick={advance}
                />,
                submitButtonPortal
            )}
        </div>
    )
}

function SurveyFreeTextView({
    step,
    submitButtonPortal,
    advance,
}: {
    step: SurveyStatement | SurveyQuestion
    submitButtonPortal: HTMLDivElement
    advance: (response: SurveyFreeTextResponse) => void
}) {
    const localize = useLocalize()
    const [freeText, setFreeText] = useState(undefined as string | undefined)
    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <div
                style={{
                    color: darkMustard,
                    fontWeight: "bold",
                    textAlign: "center",
                }}>
                {localize(step.introText)}
            </div>
            <div style={{ fontSize: 24, fontWeight: "bold", textAlign: "center", marginTop: 32 }}>
                {localize(step.statement)}
            </div>
            <div style={{ display: "flex", justifyContent: "center", marginTop: 32 }}>
                <textarea
                    value={freeText}
                    onChange={(ev) => setFreeText(ev.target.value)}
                    style={{
                        borderRadius: 20,
                        width: 510,
                        height: 192,
                        padding: 16,
                        borderColor: darkMustard,
                        borderWidth: 2,
                    }}
                />
            </div>
            {ReactDOM.createPortal(
                <SubmitButton
                    style={{
                        marginLeft: 32,
                        opacity: !freeText?.length ? 0.6 : 1,
                    }}
                    onClick={() => {
                        if (freeText) {
                            advance({
                                id: step.id,
                                type: "Free Text",
                                freeText,
                            })
                        }
                    }}
                />,
                submitButtonPortal
            )}
        </div>
    )
}

function SurveyLikertScaleView({
    step,
    submitButtonPortal,
    advance,
}: {
    step: SurveyStatement | SurveyQuestion
    submitButtonPortal: HTMLDivElement
    advance: (response: SurveyLikertScaleResponse) => void
}) {
    const localize = useLocalize()
    const [choice, setChoice] = useState(undefined as LikertScale | undefined)

    const options: LikertScale[] = [
        "Strongly Disagree",
        "Disagree",
        "Neutral",
        "Agree",
        "Strongly Agree",
        "Not Applicable",
    ]

    const OptionButton = (s: LikertScale): React.ReactNode => {
        const selected = s === choice

        return (
            <div
                key={s}
                style={{
                    display: "flex",
                    flexDirection: "column",
                    width: 120,
                    alignItems: "center",
                    marginLeft: 8,
                    marginRight: 8,
                }}>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        alignSelf: "center",
                        backgroundColor: darkMustard,
                        borderRadius: 24,
                        width: 48,
                        height: 48,
                    }}
                    onClick={() => setChoice(s)}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "white",
                            borderRadius: 21,
                            width: 42,
                            height: 42,
                        }}>
                        <div
                            style={{
                                backgroundColor: selected ? darkMustard : "white",
                                borderRadius: 15,
                                width: 30,
                                height: 30,
                            }}
                        />
                    </div>
                </div>
                <div
                    style={{
                        marginTop: 24,
                        textAlign: "center",
                        width: "90",
                        height: 40,
                    }}>
                    {s}
                </div>
            </div>
        )
    }

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <div
                style={{
                    color: darkMustard,
                    fontWeight: "bold",
                    textAlign: "center",
                }}>
                {localize(step.introText)}
            </div>
            <div style={{ fontSize: 24, fontWeight: "bold", textAlign: "center", marginTop: 32 }}>
                {localize(step.statement)}
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 32,
                    backgroundColor: "#FAF3E7",
                    borderRadius: 20,
                    padding: 30,
                }}>
                {options.map(OptionButton)}
            </div>
            {ReactDOM.createPortal(
                <SubmitButton
                    style={{
                        marginLeft: 32,
                        opacity: choice === undefined ? 0.6 : 1,
                    }}
                    onClick={() => {
                        if (choice) {
                            advance({
                                id: step.id,
                                type: "Likert Scale",
                                choice,
                            })
                        }
                    }}
                />,
                submitButtonPortal
            )}
        </div>
    )
}

function SurveyMultipleChoiceView({
    step,
    submitButtonPortal,
    advance,
}: {
    step: SurveyStatement | SurveyQuestion
    submitButtonPortal: HTMLDivElement
    advance: (response: SurveyMultipleChoiceResponse) => void
}) {
    const [choices, setChoices] = useState([] as SurveyMultipleChoiceSelectedOption[])
    const params = step.answerType as SurveyMultipleChoice
    const localize = useLocalize()

    const OptionButton = (s: SurveyMultipleChoiceOption): React.ReactNode => {
        const selected = choices.some((c) => c.selectedOption === s.id)

        return (
            <div
                key={s.id.toString()}
                style={{
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "white",
                    borderWidth: 2,
                    borderColor: selected ? darkMustard : "#FAF3E7",
                    borderStyle: "solid",
                    borderRadius: 12,
                    width: "100%",
                    margin: 6,
                }}
                onClick={() => {
                    if (selected)
                        choices.splice(
                            choices.findIndex((c) => c.selectedOption === s.id),
                            1
                        )
                    else choices.push({ selectedOption: s.id })
                    setChoices([...choices])
                }}>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                    }}>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: 28,
                            height: 28,
                            borderWidth: 2,
                            backgroundColor: selected ? darkMustard : "transparent",
                            borderColor: selected ? darkMustard : "#B0B9BF",
                            borderStyle: "solid",
                            borderRadius: 6,
                            margin: 18,
                        }}>
                        {selected && <Checkmark />}
                    </div>
                    {s.content}
                </div>
            </div>
        )
    }

    const Checkmark = () => {
        return (
            <svg
                width="19"
                height="14"
                viewBox="0 0 19 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M18.6024 0.410857C19.1325 0.957792 19.1325 1.84164 18.6024 2.38857L7.74463 13.5898C7.21447 14.1367 6.35772 14.1367 5.82756 13.5898L0.397495 7.98919C-0.132498 7.44225 -0.132498 6.5584 0.397495 6.01147C0.927574 5.46454 1.78686 5.46454 2.31703 6.01147L6.74792 10.6188L16.6853 0.410857C17.2155 -0.136952 18.0722 -0.136952 18.6024 0.410857Z"
                    fill="white"
                />
            </svg>
        )
    }

    const canSubmit =
        choices.length >= (params.minChoices ?? 0) &&
        choices.length <= (params.maxChoices ?? choices.length)
    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <div
                style={{
                    color: darkMustard,
                    fontWeight: "bold",
                    textAlign: "center",
                }}>
                {localize(step.introText)}
            </div>
            <div style={{ fontSize: 24, fontWeight: "bold", textAlign: "center", marginTop: 32 }}>
                {localize(step.statement)}
            </div>
            <div style={{ marginTop: 32 }}>{params.options.map(OptionButton)}</div>
            {ReactDOM.createPortal(
                <SubmitButton
                    style={{
                        marginLeft: 32,
                        opacity: canSubmit ? 1 : 0.6,
                    }}
                    onClick={() => {
                        if (canSubmit) {
                            advance({
                                id: step.id,
                                type: "Multiple Choice",
                                choices,
                            })
                        }
                    }}
                />,
                submitButtonPortal
            )}
        </div>
    )
}

function SurveyActionCardView({
    step,
    submitButtonPortal,
    advance,
}: {
    step: SurveyActionCard
    submitButtonPortal: HTMLDivElement
    advance: (response: SurveyActionCardResponse) => void
}) {
    const localize = useLocalize()
    const [choice, setChoice] = useState(undefined as ActionCardLikertScale | undefined)
    const { actionCard } = step

    const options: ActionCardLikertScale[] = [
        "To a very great extent",
        "To a great extent",
        "To a moderate extent",
        "To a small extent",
        "Not at all",
    ]

    const OptionButton = (s: ActionCardLikertScale): React.ReactNode => {
        const selected = s === choice

        return (
            <div
                key={s}
                style={{
                    display: "flex",
                    flexDirection: "column",
                    width: 120,
                    alignItems: "center",
                    marginLeft: 8,
                    marginRight: 8,
                }}>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        alignSelf: "center",
                        backgroundColor: darkMustard,
                        borderRadius: 24,
                        width: 48,
                        height: 48,
                    }}
                    onClick={() => setChoice(s)}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "white",
                            borderRadius: 21,
                            width: 42,
                            height: 42,
                        }}>
                        <div
                            style={{
                                backgroundColor: selected ? darkMustard : "white",
                                borderRadius: 15,
                                width: 30,
                                height: 30,
                            }}
                        />
                    </div>
                </div>
                <div
                    style={{
                        marginTop: 24,
                        textAlign: "center",
                        width: "90",
                        height: 40,
                    }}>
                    {s}
                </div>
            </div>
        )
    }

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <div
                style={{
                    borderRadius: 20,
                    boxShadow: "0px 4px 4px 0px #00000040",
                    padding: 24,
                    backgroundColor: "white",
                    display: "flex",
                    flexDirection: "column",
                    marginTop: 0,
                    marginBottom: 85,
                    marginLeft: "auto",
                    marginRight: "auto",
                    width: 354,
                }}>
                <div style={{ fontSize: 18, fontWeight: 700 }}>{localize(actionCard.title)}</div>
                <div style={{ marginTop: 16 }}>{localize(actionCard.body)}</div>
            </div>
            <div
                style={{
                    color: darkMustard,
                    fontWeight: "bold",
                    textAlign: "center",
                }}>
                {localize(step.introText)}
            </div>
            <div style={{ fontSize: 24, fontWeight: "bold", textAlign: "center", marginTop: 32 }}>
                {localize(step.statement)}
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 32,
                    backgroundColor: "#FAF3E7",
                    borderRadius: 20,
                    padding: 30,
                }}>
                {options.map(OptionButton)}
            </div>
            {ReactDOM.createPortal(
                <SubmitButton
                    style={{
                        marginLeft: 32,
                        opacity: choice === undefined ? 0.6 : 1,
                    }}
                    onClick={() => {
                        if (choice) {
                            advance({
                                id: step.id,
                                type: "Action Card",
                                choice,
                            })
                        }
                    }}
                />,
                submitButtonPortal
            )}
        </div>
    )
}

function SurveyCompletedModal(exit: () => void) {
    return Modal((close) => {
        return (
            <div
                style={{
                    backgroundColor: "white",
                    borderRadius: 8,
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    padding: 44,
                }}>
                <div
                    style={{
                        fontSize: 24,
                        fontWeight: "bold",
                        margin: 36,
                    }}>
                    Survey Complete
                </div>
                <div style={{ margin: "0 auto", marginTop: 36 }}>
                    <CheckCircle />
                </div>
                <div
                    style={{
                        fontSize: 16,
                        margin: 36,
                    }}>
                    <div>Your answers have been recorded.</div>
                    <div>Thank you for responding.</div>
                </div>
                <ModalButton
                    onClick={() => {
                        close(undefined)
                        exit()
                    }}>
                    Close
                </ModalButton>
            </div>
        )
    })
}
