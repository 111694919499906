import React, { useContext, useState } from "react"
import {
    Assert,
    GetReflectionInfo,
    GetType,
    GetTypeAlias,
    prettyCamel,
    Property,
    ReplaceValuesByType,
} from "../../reactor"
import { DocumentContext } from "./DocumentContext"
import { DiagnosticView } from "./DiagnosticView"
import { ToolButton } from "./ToolButton"
import { PropRow } from "./PropRow"
import { ExtractPrimaryItemFromWidget } from "../../packages/widgets/Helpers"
import { WidgetView } from "../../packages/widgets/WidgetView"
import { ButtonWidget } from "../../packages/widgets/ButtonWidget"
import type { Widget } from "../../packages/widgets/Widget"
import { DocumentPicker } from "./DocumentPicker"
import { ListContext } from "./ListContext"
import { WidgetContext } from "../../packages/widgets/WidgetContext"
import { useIsReadonlyField } from "./ObjectContext"
import { useHover } from "../../packages/hooks/useHover"
import { useNavigate } from "../../packages/hooks/useNavigate"
import { useDirtyContext } from "../../packages/editing/DirtyContext"
import { Modal } from "../../packages/modal/Modal"
import { Translations } from "../../packages/localization/client-side/Dictionary"
import { ColorStyles } from "../../packages/ui"
import { useDocumentObjectView } from "../client"

export type RefViewProps = {
    obj: any
    property: Property
    buttons: ToolButton[]
    isArray?: boolean
    isEmbedded?: boolean
    filter?: string
    label?: string
}

export function RefView(props: RefViewProps) {
    const isReadonly = useIsReadonlyField(props.property)
    return (
        <PropRow
            isReadonly={isReadonly}
            label={
                props.label ??
                (!isNaN(parseInt(props.property.name))
                    ? undefined
                    : prettyCamel(props.property.name))
            }
            buttons={props.buttons}
            description={props.property.description}
            badge={
                <DiagnosticView value={props.obj[props.property.name]} property={props.property} />
            }
            isEmbedded={props.isEmbedded}>
            <RefViewInner2 {...props} />
        </PropRow>
    )
}

export function RefViewInner2({ obj, property, isEmbedded }: RefViewProps) {
    const value = obj[property.name]
    const docContext = useContext(DocumentContext)
    const { setDirty } = useDirtyContext()
    const { hover, hoverProps } = useHover()
    const navigate = useNavigate()
    const listContext = useContext(ListContext)
    const wc = useContext(WidgetContext)
    const isReadonly = useIsReadonlyField(property)

    const reference = typeof property.type === "object" ? property.type.reference : undefined
    if (!reference) {
        return <div>No reference info available</div>
    }

    /** References picked from picker */
    const [refCache] = useState<any>({})

    const itemType = GetType(reference.typeName)
    const itemName =
        typeof itemType === "object"
            ? itemType.tags?.translation ?? { en: itemType.alias ?? reference.typeName }
            : { en: reference.typeName }

    const refCollection = GetReflectionInfo().collections.find(
        (c) => GetTypeAlias(c.type.typeArgs[0]) === reference.typeName
    )
    const optionsFunc = property.tags?.options || listContext?.optionsFunc

    function tryGetWidgetFromDocumentContext(): Widget | undefined {
        if (!docContext) return undefined
        if (!reference) return undefined

        docContext.refs ??= {}

        if (!refCollection && !optionsFunc)
            return <div>No valid source collection or options specified</div>

        let refType = docContext.refs[reference.typeName]
        if (!refType) refType = docContext.refs[reference.typeName] = {}
        let refField = refType ? refType[reference.fieldName] : undefined
        if (!refField) refField = refType[reference.fieldName] = {}
        const refWidget = (refField ? refField[value] : undefined) || refCache[value]
        return refWidget ? ExtractPrimaryItemFromWidget(refWidget) : value
    }

    let widget = tryGetWidgetFromDocumentContext()

    // If there is no document context to get a pre-rendered widget from, let's
    // do a fallback rendering of the widget with an extra API call.
    // This is not the default since there is potentially hundreds of references
    // in a document, and we don't want to make hundreds of API calls.
    const fallbackWidget = useDocumentObjectView(
        typeof widget === "object" || !value || typeof value !== "string"
            ? null // Don't call if we don't have to, disable the hook by passing null
            : {
                  objects: [
                      {
                          obj: value,
                          typeAlias: reference.typeName,
                      },
                  ],
              }
    )

    if (fallbackWidget.data?.views[0]) {
        widget = fallbackWidget.data?.views[0]
    }

    const isInternalRef = !refCollection

    const undefinedPlaceholder = property.tags?.placeholder || ""

    return (
        <div
            {...hoverProps}
            className={isEmbedded ? undefined : "form-control"}
            style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                paddingTop: 8,
                paddingBottom: 8,
                paddingLeft: isEmbedded ? 12 : undefined,
            }}>
            {widget && (
                <div
                    style={{ flex: 1, position: "relative" }}
                    title={
                        isInternalRef
                            ? `This is a reference to a ${prettyCamel(
                                  reference.typeName,
                                  false
                              )} inside this ${prettyCamel(
                                  (docContext?.type && GetTypeAlias(docContext.type)) || "document",
                                  false
                              )}.`
                            : undefined
                    }>
                    {isInternalRef && (
                        <i
                            className="fas fa-external-link-square"
                            style={{
                                position: "absolute",
                                color: ColorStyles["gray-cool"][600],
                                fontSize: 12,
                                bottom: 0,
                                top: 28,
                                height: 12,
                                borderRadius: 2,
                                backgroundColor: ColorStyles["gray-cool"][100],
                            }}
                        />
                    )}
                    <WidgetView value={widget} />
                </div>
            )}
            {value === undefined && undefinedPlaceholder && (
                <div style={{ marginRight: 16 }}>{undefinedPlaceholder}</div>
            )}

            {(!value || hover) && !isReadonly && (
                <ButtonWidget
                    action={ChangeOrSpecify}
                    text={value ? Translations.Change() : Translations.Specify()}
                />
            )}
            {value && hover && refCollection && (
                <ButtonWidget
                    action={() => navigate(`/studio/${refCollection.name}/${value}`)}
                    text={Translations.Open()}
                />
            )}
        </div>
    )
    function ChangeOrSpecify() {
        if (!reference) return

        return Modal<void>((close) => (
            <DocumentPicker
                collection={optionsFunc ?? Assert(refCollection).name}
                obj={obj}
                document={docContext?.doc}
                current={widget}
                refKey={reference.fieldName}
                itemName={itemName}
                itemSelected={(row, scope) => {
                    const fieldValue = row._refKey as string

                    // Put the new row in the widget cache
                    refCache[fieldValue] = row

                    // Update the document
                    if (scope === "only-this") {
                        if (value !== fieldValue) {
                            obj[property.name] = fieldValue
                            setDirty()

                            // Changing a reference tend to affect available options, so
                            // let's refresh the document metadata
                            void wc?.refresh()
                        }
                    } else if (scope === "all-in-document") {
                        const doc = docContext?.doc
                        const type = docContext?.type
                        if (!doc) throw new Error("No document!")
                        if (!type) throw new Error("No type!")
                        let count = 0
                        ReplaceValuesByType(doc, type, (v, type) => {
                            if (typeof type === "object" && type.reference) {
                                if (v === value) {
                                    count++
                                    return fieldValue
                                }
                            }
                            return v
                        })
                        if (count > 0) {
                            setDirty()
                            void wc?.refresh()
                        }
                        alert(Translations.ReplacedXReferences(count))
                    } else {
                        throw new Error("Unknown scope: " + scope)
                    }

                    close()
                }}
                cancel={close}
            />
        ))
    }
}
