import React from "react"
import "../../../packages/ssr/SSR2"
import "../studio/SSMLEditor"
import App from "./App"
import { BrowserRouter } from "react-router-dom"
import { Login } from "../../../packages/ssr/Login"
import { defaults } from "./client"
import { defaults as studioDefaults } from "../../../studio/client"
import { InitWebApp } from "../../../packages/ssr/InitWebApp"
import { Missing } from "./Missing"
import { VerifyEmailPage } from "./VerifyEmailPage"
import { SidebarTheme } from "../../../studio/Views/SidebarTheme"
import { ColorStyles } from "../../../packages/ui"

ColorStyles.primary = ColorStyles.teal
SidebarTheme.light = false
SidebarTheme.background = "linear-gradient(to bottom, rgba(3, 77, 99, 1), rgba(23, 0, 48, 1))"

InitWebApp(() => (
    <Login
        always={false}
        returnTo={"/"}
        errorDisplay={(error) =>
            error === "You must confirm your email address first!" ? (
                /** Display this friendlier and more helpful screen. */
                <VerifyEmailPage />
            ) : (
                <Missing
                    data={null}
                    loading={false}
                    error={{ type: "Authentication failed", detail: error }}
                />
            )
        }
        tokenReady={(token) => {
            defaults.headers.Authorization = `Bearer ${token}`
            studioDefaults.headers.Authorization = `Bearer ${token}`
        }}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Login>
))
