import React, { useContext } from "react"
import { Property } from "../../reactor/Types/Type"
import { DiagnosticView } from "./DiagnosticView"
import { PropRow } from "./PropRow"
import { ToolButton } from "./ToolButton"
import { ErrorContext } from "./ErrorContext"
import { useIsReadonlyField } from "./ObjectContext"
import { useDirtyContext } from "../../packages/editing/DirtyContext"
import { ToggleWidget } from "../../packages/widgets/ToggleWidget"

export function BooleanView({
    obj,
    buttons,
    property,
    label,
}: {
    obj: any
    label?: string
    property: Property
    buttons: ToolButton[]
}) {
    const { setDirty } = useDirtyContext()
    const { error } = useContext(ErrorContext)
    const value = obj[property.name]
    const isReadonly = useIsReadonlyField(property)

    return (
        <PropRow
            isReadonly={isReadonly}
            label={label}
            buttons={buttons}
            description={property.description}
            childrenInBadge={true}>
            <div
                style={{
                    flex: 1,
                    backgroundColor: error ? "#f004" : undefined,
                    marginTop: 4,
                }}>
                {!isReadonly && (
                    <ToggleWidget
                        value={value}
                        onChanged={(v) => {
                            obj[property.name] = v
                            setDirty()
                        }}
                    />
                )}
                {isReadonly && <div>{JSON.stringify(value)}</div>}
                <DiagnosticView value={value} property={property} />
            </div>
        </PropRow>
    )
}
