import React, { useLayoutEffect, useRef, useState } from "react"

/** Renders the child components in a letterbox. Injects target width/height for child content to
 * render with.
 *
 *
 * Usage example:
 *
 * ```tsx
 * <AspectLockLetterbox aspect={16 / 9} style={{ backgroundColor: "black" }}>
        {(width, height) => (
            <iframe
                ref={ref}
                src={`https://sayfr-static.fuseapps.no/${props.dataset}/index.html`}
                style={{
                    border: "none",
                    width,
                    height,
                }}
            />
        )}
    </AspectLockLetterbox>
    ```
 * */
export function AspectLockLetterbox({
    aspect,
    style,
    children,
}: {
    aspect: number
    style?: React.CSSProperties
    children: (width: number | string, height: number | string) => React.ReactNode
}) {
    const divRef = useRef<HTMLDivElement>(null)

    const [size, setSize] = useState<{ width: string | number; height: string | number }>({
        width: "100%",
        height: "100%",
    })

    useLayoutEffect(() => {
        function updateSize() {
            const w = divRef.current?.clientWidth ?? 1
            const h = divRef.current?.clientHeight ?? 1

            const actualAspect = w / h

            if (actualAspect > aspect) {
                setSize({
                    width: h * aspect,
                    height: "100%",
                })
            } else {
                setSize({
                    width: "100%",
                    height: w / aspect,
                })
            }
        }
        window.addEventListener("resize", updateSize)
        updateSize()
        return () => window.removeEventListener("resize", updateSize)
    }, [])

    return (
        <div
            ref={divRef}
            style={{
                width: "100%",
                maxWidth: "100%",
                height: "100%",
                maxHeight: "100%",
                background: "black",
                position: "absolute",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                ...style,
            }}>
            {children(size.width, size.height)}
        </div>
    )
}
