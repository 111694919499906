import React, { useState } from "react"
import { ImageToCSS } from "../../../reactor/Types/File"
import { Reflection } from "./client"
import { peach, peachFaded, yellowBG } from "./colors"
import { PartialCircle } from "./PartialCircle"
import { SubmitButton } from "./SubmitButton"
import { MarkdownView } from "../../../packages/markdown-edit/MarkdownView"

export function ReflectionView(props: { reflection: Reflection; goNext: () => void }) {
    const [version, setVersion] = useState({})
    const [countdownStart, setCountdownStart] = useState(Date.now())
    const [running, setRunning] = React.useState(false)
    const [time, setTime] = React.useState(props.reflection.reflectionSeconds)

    function start() {
        setCountdownStart(Date.now())
        setRunning(true)
    }
    function pause() {
        setTime(Math.max(0, time - (Date.now() - countdownStart) / 1000))
        setRunning(false)
    }
    function restart() {
        setTime(props.reflection.reflectionSeconds)
        setRunning(false)
    }

    if (running) {
        requestAnimationFrame(() => setVersion({}))
    }

    const timeLeft = Math.max(0, running ? time - (Date.now() - countdownStart) / 1000 : time)
    const minutes = Math.floor(timeLeft / 60)
    const seconds = Math.floor(timeLeft % 60)

    return (
        <div
            style={{
                position: "absolute",
                right: 0,
                top: 0,
                bottom: 0,
                left: 0,
                display: "flex",
                flexDirection: "row",
            }}>
            <div
                style={{
                    flex: 2,
                    backgroundImage: props.reflection.media
                        ? ImageToCSS(props.reflection.media)
                        : undefined,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                }}
            />
            <div
                style={{
                    flex: 1,
                    fontSize: 26,
                    color: "#131D22",
                    backgroundColor: yellowBG,
                    padding: 32,
                    paddingTop: 96,
                    display: "flex",
                    flexDirection: "column",
                }}>
                <div style={{ flex: 2 }}>
                    <MarkdownView value={props.reflection.body} />
                </div>
                <div style={{ width: "100%", flex: 2, position: "relative" }}>
                    <div
                        style={{
                            position: "absolute",
                            left: 0,
                            right: 0,
                            top: "60%",
                            textAlign: "center",
                            fontSize: 48,
                        }}
                        onClick={() => (timeLeft > 0 ? (running ? pause() : start()) : restart())}>
                        <i
                            className={`fas fa-${
                                timeLeft > 0 ? (running ? "pause" : "play") : "redo"
                            }`}
                            style={{ alignSelf: "center" }}
                        />
                    </div>

                    <div style={{ margin: 32 }}>
                        <svg style={{ width: "100%" }} viewBox="0, 0, 220, 220">
                            <PartialCircle
                                radius={100}
                                percent={100}
                                strokeWidth={14}
                                color={peachFaded}
                                animate={false}
                            />
                            <PartialCircle
                                color={peach}
                                radius={100}
                                percent={(timeLeft / props.reflection.reflectionSeconds) * 100}
                                strokeWidth={14}
                                animate={true}
                            />
                            <text x="50%" y="50%" textAnchor="middle" dominantBaseline="middle">
                                {`${minutes}:${seconds < 10 ? "0" : ""}${seconds}`}
                            </text>
                        </svg>
                    </div>
                </div>
                <div
                    style={{
                        flex: 1.5,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                    <SubmitButton text="NEXT" onClick={props.goNext} />
                </div>
            </div>
        </div>
    )
}
